import React,{useState,useEffect,useRef} from 'react'
import Main from '../componentes/Main'
import Axios from 'axios';
import EXIF from 'exif-js'

export const SubirFoto = ({cerrar})=>{
    const [foto,setFoto]=useState(null)
    const [imagenOriginal,setImagenOriginal]=useState(null)
    const [target,setTarget] = useState(null)
    const [fotografia,setFotografia]=useState(null)
    const [logs,setLogs]=useState(null)
    const canvasRef = useRef()
    const canvasContextRef = useRef()
    const inputRef = useRef()
    const [file,setFile]=useState(null)

    useEffect(()=>{
        const context = canvasRef.current.getContext("2d");
        canvasContextRef.current =  context
        buscarFoto()
    },[])

    const buscarFoto = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/foto/1`)
            setFotografia(data)
            console.log('pez',`data:application/pdf;base64,${data}`)
        }catch(err){
            console.log(err)
        }
    }

    const buscarLogs = async ()=>{
        try{

            const {data} = await Axios.get(`/api/tablasgenerales/leerlogs`)
            alert(JSON.stringify(data))
            setLogs(data)
        }catch(err){
            console.log(err)
        }
    }

    const fileToBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          cb(null, reader.result)
        }
        reader.onerror = function (error) {
          cb(error, null)
        }
      }

      const onUploadFileChange2 = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
          return
        }
        fileToBase64(target.files[0], (err, result) => {
          if (result) {
            setFile(result)
            console.log('pablo',result)
          }
        })
      }

      const onUploadFileChange = ({ target }) => {
        if (target.files < 1 || !target.validity.valid) {
          return
        }
        fileToBase64(target.files[0], (err, result) => {
          if (result) {
            onClickHandler2(result)
          }
        })
      }

      

    const handleFoto = (e)=>{

        if(!e.target.files[0]){
            return
        }

        const file = e.target.files[0];

        const reader = new FileReader();

        reader.readAsDataURL(file);

        reader.addEventListener('load',()=> setFoto(reader.result));

        return 

       /* if(!(file.type.substring(0,5)=='image')){
            return
        }
*/


        reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            // Converted image is available here.

            var myImage = new Image(); // Creates image object
            myImage.src = e.target.result; // Assigns converted image to image object

         

            myImage.onload = function(ev) {

                EXIF.getData(myImage, function() {
                    alert(EXIF.getTag(this, "Orientation") || 1);
                });

                EXIF.getData(myImage, function() {

                    let orientation = EXIF.getTag(this,"Orientation");

                  

                    //alert(orientation)

                    /*const scaleSize = 300 / ev.target.width;
                    canvasRef.current.height = ev.target.height * scaleSize;
                    canvasRef.current.width=300
*/
                    const scaleSize = 300 / ev.target.width;
                    canvasRef.current.height = ev.target.height * scaleSize;
                    canvasRef.current.width=300

                    const width = canvasRef.current.width;
                    const height = canvasRef.current.height
                    const widthsty = canvasRef.current.style.width
                    const heightsty = canvasRef.current.style.height
                    canvasContextRef.current.scale(1,1)
                    if (orientation) {

                       // alert(orientation)
                        /*if (orientation > 4) {
                            canvasRef.current.width  = height; canvasRef.current.style.width  = heightsty;
                            canvasRef.current.height = width;  canvasRef.current.style.height = widthsty;
                        }*/
                      /*  switch (orientation) {
                            case 2: canvasContextRef.current.translate(width, 0);     canvasContextRef.current.scale(-1,1); break;
                            case 3: canvasContextRef.current.translate(width,height); canvasContextRef.current.rotate(Math.PI); break;
                            case 4: canvasContextRef.current.translate(0,height);     canvasContextRef.current.scale(1,-1); break;
                            case 5: canvasContextRef.current.rotate(0.5 * Math.PI);   canvasContextRef.current.scale(1,-1); break;
                            case 6: canvasContextRef.current.rotate(0.5 * Math.PI);   canvasContextRef.current.translate(0,-height); break;
                            case 7: canvasContextRef.current.rotate(0.5 * Math.PI);   canvasContextRef.current.translate(width,-height); canvasContextRef.current.scale(-1,1); break;
                            case 8: canvasContextRef.current.rotate(-0.5 * Math.PI);  canvasContextRef.current.translate(-width,0); break;
                        }*/
                       

                      }
                      canvasContextRef.current.drawImage(myImage,0,0,canvasRef.current.width,canvasRef.current.height); // Draws the image on canvas
                      const srcEncoded = canvasContextRef.current.canvas.toDataURL(ev.target, "image/jpeg");
                      setTarget(ev.target)
                      setFoto(srcEncoded)
                });

                /*const scaleSize = 300 / ev.target.width;
                canvasRef.current.height = ev.target.height * scaleSize;
                canvasRef.current.width=300
                canvasContextRef.current.drawImage(myImage,0,0,canvasRef.current.width,canvasRef.current.height); // Draws the image on canvas
                const srcEncoded = canvasContextRef.current.canvas.toDataURL(ev.target, "image/jpeg");
                setTarget(ev.target)
                setFoto(srcEncoded)*/
            }
            setImagenOriginal(myImage)
            
         }
         
        //setFoto(e.target.files[0])
    }

    const rotacion = ()=>{
        canvasContextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
        canvasContextRef.current.translate(canvasRef.current.width, canvasRef.current.height);
        canvasContextRef.current.rotate(-90 * Math.PI / 180);

        canvasContextRef.current.drawImage(imagenOriginal,0,0,canvasRef.current.width,canvasRef.current.height); // Draws the image on canvas
        const srcEncoded = canvasContextRef.current.canvas.toDataURL(target, "image/jpeg");
        setFoto(srcEncoded)
    }


   const copiarImagen = ()=>{
    var img = document.getElementById("pepe");
    canvasContextRef.current.drawImage(img, 10, 10);

   }

   const onClickHandler = async () => {

        console.log(JSON.stringify(file))
        const formulario = new FormData() 
        formulario.append('file',file)
        formulario.append('teta','amigito')

        const objetoEnviado = {file:file}
       /* Axios.post(`/api/tablasgenerales/subirfotomemoria`,data, { 
            // receive two    parameter endpoint url ,form data
        })
        */

        /*
        Axios.post(`/api/tablasgenerales/postphotodisk`,formulario, { 
            // receive two    parameter endpoint url ,form data
        })
        .then(res => { // then print response status
            console.log(res)
        })
        .catch(err=>{
            alert(JSON.stringify(err))
        })*/
        try{
          //  const { data } = await Axios.post(`/api/tablasgenerales/postphotomem`,formulario);
          const { data } = await Axios.post(`/api/tablasgenerales/base64`,objetoEnviado);
          console.log(foto)
            //const { data } = await Axios.post(`/api/tablasgenerales/postphotodisk`,formulario);
            
            buscarFoto()
            setFoto(null)
        }catch(err){
            alert(JSON.stringify(err))
        }
     
      }

    
   const onClickHandler2 = async (file) => {

    const formulario = new FormData() 
    formulario.append('file',file)

    const objetoEnviado = {file:file}
    try{
      const { data } = await Axios.post(`/api/tablasgenerales/base64`,objetoEnviado);
      
    }catch(err){
        alert(JSON.stringify(err))
    }
 
  }

    return <Main center>
        <div className='flex f-col justify-center items-center mt-8'>
            <canvas ref={canvasRef}  style={{border:'solid'}}>

            </canvas>
            <button onClick={rotacion}>rotar</button>
            <button onClick={copiarImagen}>copiar</button>
            {fotografia && <img className="p-2 mr-auto ml-auto" alt="Foto inexistente" width="300" height="300" src={`data:application/pdf;base64,${fotografia}`} />}
            <button onClick={buscarLogs}>Logs</button>
            {logs && <div>
                {logs.map(item=><h1>{item}</h1>)}
                </div>}
            <input type="file" onChange={onUploadFileChange} accept="pdf" ref={inputRef}/> 
            {foto && <button className='bg-azul-tema p-4 text-white w-100 mt-4' onClick={()=>setFoto(null)}>Borrar</button>}
            <button className='bg-tomato p-4 text-white w-100 mt-4' onClick={cerrar}>Cerrar</button>
            {file && <div className='mt-4 mb-4'>
                <p>Filename: {file.name}</p>
                <p>File type: {file.type}</p>
                <p>File size: {file.size} bytes</p>
                <button className='bg-tomato p-4 text-white w-100 mt-4' onClick={onClickHandler}>Subir</button>

                <ImageThumb image={file}/>
            </div>}
        </div>
  </Main> 
}

const ImageThumb = ({ image }) => {
//    return <img src={URL.createObjectURL(image)} alt={image.name} />;
    return <img src={image} width='300' id="pepe" alt={'image.name'} />;
  };