import React, { useState, useEffect } from 'react';
import { Link, withRouter,NavLink,useLocation} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faPlane,faMoneyCheckAlt, faChartLine,faCoins, faSearchDollar, faChurch, faPowerOff, faBars, faCalendarAlt as calendar2, faClone,faUsers, faQrcode, faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons';
import { faFileAlt, faCalendarAlt,faWindowClose,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {useAlumno} from '../Context/alumnoContext';
import useModal from '../hooks/useModal';
import Modal from './Modal';
import Busqueda from './Busqueda';
import imagen from '../logoemc.PNG';
import Logo from '../componentes/Logo'
const id_autorizados = [33,14,123,27,2487,2316,200]
const id_programador = 2487

export default function Nav({ usuario, logout, cuatrimestreActivo, mostrarCredencial }) {

  const {mostrarBusquedaAlumnos, 
        habilitarBusquedaAlumnos,
        desHabilitarBusquedaAlumnos,
        infoObrero,
        ocultarMenu,
        verMenu,vistaCargada} = useAlumno();
  const {toggle, isShowing } = useModal();
  const [componenteModal,setComponenteModal]= useState(null)
  const [titulo,setTitulo]= useState('')
  const [abrirMenuVertical,setAbrirMenuVertical]= useState(false)
  const [mostrar, setMostrar] = useState(false);
  const [obreroSeleccionado, setObreroSeleccionado] = useState(null);
  const location = useLocation();  



useEffect(()=>{ // procesa los cierres de modales por boton cerrar y no por otro motivo
    if (!isShowing){

        if (obreroSeleccionado){
            setObreroSeleccionado(null)
        }
        
    }
},[isShowing])

  const toggleMenuVertical = ()=>{
    setAbrirMenuVertical(!abrirMenuVertical)
  }

  const switchVistaBusquedaAlumnos = ()=>{

    if (mostrarBusquedaAlumnos){
          desHabilitarBusquedaAlumnos();
      }else{
         habilitarBusquedaAlumnos()
      }
  }

const abrirAbm = ()=>{
    setObreroSeleccionado(usuario.id_obrero)
    toggle()
}

const switchMostrar=()=>{
    if (mostrar){
        setMostrar(false)
    }else{
        setMostrar(true)
    }
}

const mostrarMenuLateral=()=>{
    setMostrar(true)
}

function finalizarAltaOcopia (alta,id){

  setObreroSeleccionado(null)
  toggle()

}



const noMostrarMenuLateral=()=>{
  setMostrar(false)
}

  useEffect(()=>{
    console.log('se carga el nav')
  },[cuatrimestreActivo])

  
  return (
    <div>
   
    {usuario && false && <div onMouseEnter={mostrarMenuLateral} onMouseLeave={noMostrarMenuLateral}  className={mostrar ? "flex f-row wrapper_nav mostrar" : "flex f-row wrapper_nav nomostrar_nav"} onClick={switchMostrar}>
        <div id="slide2">
            <span onClick={switchMostrar} className="cursor-pointer mr-2 ml-2 color-tomato flex justify-content-end" >
                        { !mostrar && <FontAwesomeIcon title="Otras operaciones" className="mostrar-menu-lateral_nav" icon={faBars}/>}
            </span>  
        </div>
    </div>}

    {/*usuario && verMenu && <div onMouseEnterx={mostrarMenuLateral} onMouseLeavex={noMostrarMenuLateral}  className={!mostrar ? "flex f-row wrapper_nav mostrar" : "flex f-row wrapper_nav nomostrar_nav"} onClick={ocultarMenu}>*/}
    {usuario && verMenu && <div className={!mostrar ? "flex f-row wrapper_nav mostrar" : "flex f-row wrapper_nav nomostrar_nav"}>
        <div id="slide2">
            {false && <span onClick={ocultarMenu} className="cursor-pointer mr-2 ml-2 color-tomato flex justify-content-end" >
                        { !mostrar && <FontAwesomeIcon title="Otras operaciones" className="mostrar-menu-lateral_nav" icon={faBars}/>}
            </span> } 
            <MenuVertical logout={logout} setComponenteModal={setComponenteModal} toggle={toggle} setTitulo={setTitulo} toggleMenuVertical={toggleMenuVertical} usuario={usuario} infoObrero={infoObrero} />
        </div>
    </div>}

    <nav className="Nav">
      <ul className="Nav__links">
       {!usuario && <li>
          <Link className="razon-social" to="/">
            Sistema de consulta - UAD 
          </Link>
       </li>}
       <li>{JSON.usuario}</li>
        {usuario && location.pathname.trim()=='/home' && <LoginRoutes toggle={toggle} 
                      usuario={usuario} 
                      logout={logout} 
                      switchVistaBusquedaAlumnos={switchVistaBusquedaAlumnos} 
                      setComponenteModal={setComponenteModal} 
                      setTitulo={setTitulo}
                      abrirMenuVertical={abrirMenuVertical} 
       toggleMenuVertical={toggleMenuVertical} />}
      </ul>
    </nav>
    { isShowing && true && <Modal hide={toggle} isShowing={isShowing} titulo={titulo} estiloWrapper={{background:'white'}}>
                           <SeleccionarComponenteModal componente={componenteModal}
                           />
                    </Modal>}      
    
    </div>

  );
}

function LoginRoutes({ usuario, 
  logout, 
  switchVistaBusquedaAlumnos,
  toggle, 
  setComponenteModal, 
  setTitulo,
  abrirMenuVertical,
  toggleMenuVertical }) {

const estilo_prueba = {color:'white',background:'tomato',padding:'3px'}

return (
<>

{/*<li title="Editar mis datos personales" className="listado-al p-2">
        <div className="text-center"> 
              <Link className="Nav__link"  to="/info">
                <FontAwesomeIcon icon={faUser} />
                <p className="text-large color-63 text-center">Datos personales</p>
              </Link>
            </div>
        </li> 
        <li title="Credencial digital" className="listado-al p-2">
            <div className="text-center"> 
              <Link className="Nav__link"  to="/credencial">
                <FontAwesomeIcon icon={faIdCard} />
                <p className="text-large color-63 text-center">Credencial</p>
              </Link>
            </div>
        </li>  
        <li title="Ver mis iglesias y ministerios" className="listado-al p-2">
            <div className="text-center"> 
              <Link className="Nav__link"  to="/listado/iglesias">
                <FontAwesomeIcon icon={faChurch} />
                <p className="text-large color-63 text-center">Iglesias y ministerios</p>
              </Link>
            </div>
        </li>  */}
        <li title="Ver mi cuenta" className="listado-al p-2">
            <div className="text-center"> 
              <Link className="Nav__link"  to="/cuenta">
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                <p className="text-large color-63 text-center">Mi cuenta</p>
              </Link>
            </div>
        </li>
        <li title="Ver mis aportantes" className="listado-al p-2">
            <div className="text-center"> 
              <Link className="Nav__link"  to="/aportantes">
                <FontAwesomeIcon icon={faUsers} />
                <p className="text-large color-63 text-center">Mis aportantes</p>
              </Link>
            </div>
        </li>
        <li title="Ver mis fondos" className="listado-al p-2">
            <div className="text-center"> 
              <Link className="Nav__link"  to="/fondos">
                <FontAwesomeIcon icon={faCoins} />
                <p className="text-large color-63 text-center">Mis fondos</p>
              </Link>
            </div>
        </li>
        {id_autorizados.some(item=>item==usuario.id_misionero) && <li title="Cargar informe de egresos" className="listado-al p-2">
            <div className="text-center relative"> 
              <Link className="Nav__link"  to="/egresos">
                <FontAwesomeIcon className='relative' icon={faPlane}/>
                <p className="text-large color-63 text-center">Informe de egresos</p>
              </Link>
            </div>
        </li>}  
        {id_autorizados.some(item=>item==usuario.id_misionero) && <li title="Cargar informe de ingresos" className="listado-al p-2">
            <div className="text-center relative"> 
              <Link className="Nav__link"  to="/ingresos">
                <FontAwesomeIcon className='relative' icon={faChartLine}/>
                <p className="text-large color-63 text-center">Informe de ingresos</p>
              </Link>
            </div>
        </li>}  
        {/*id_autorizados.some(item=>item==usuario.id_misionero) && <li title="Cargar informe de egresos 2" className="listado-al p-2">
            <div className="text-center relative"> 
              <Link className="Nav__link"  to="/egresos2">
                <FontAwesomeIcon className='relative' style={{color:'red'}} icon={faSearchDollar}/>
                <p className="text-large color-63 text-center">Egresos 2</p>
              </Link>
            </div>
        </li>*/}  
        {/*id_autorizados.some(item=>item==usuario.id_misionero) && <li title="Cargar informe de ingresos 2" className="listado-al p-2">
            <div className="text-center relative"> 
              <Link className="Nav__link"  to="/ingresos2">
                <FontAwesomeIcon className='relative' style={{color:'green'}} icon={faSearchDollar}/>
                <p className="text-large color-63 text-center">Ingresos 2</p>
              </Link>
            </div>
        </li>*/}  
        {id_programador==usuario.id_misionero && <li title="Logs" className="listado-al p-2">
            <div className="text-center relative"> 
              <Link className="Nav__link"  to="/logs">
                <FontAwesomeIcon className='relative' icon={faFileAlt}/>
                <p className="text-large color-63 text-center">Logs</p>
              </Link>
            </div>
        </li>}                
        <li title="Salir" className="listado-al p-2 ml-4">
            <div className="text-center"> 
                <div className="mr-auto ml-auto" title="Salir" onClick={logout}>
                  <FontAwesomeIcon icon={faPowerOff} />
                  <span className="text-large color-63 block">Salir</span>
                </div>
            </div>
        </li>
      
</>
);
}

function MenuVertical({setComponenteModal, 
                      toggle, 
                      setTitulo,
                      toggleMenuVertical, 
                      logout, 
                      mostrarCredencial,
                      infoObrero,usuario}){
  return(
<div className="menu-vertical-nav" onMouseLeavexxx={toggleMenuVertical}>
        <ul className="ul-ml-n20">
      <li className="listado-al">
        <Logo width="50"/>
        {infoObrero.nombre && <p className="">{infoObrero.sexo=='M' ? `Bienvenido ${infoObrero.nombre}` : infoObrero.sexo=='F' ? `Bienvenida ${infoObrero.nombre}` : infoObrero.sexo=='G' ? `Bienvenidos ${infoObrero.nombre}` : `Bienvenido ${infoObrero.nombre}`}</p>}
      </li>
      {/*  <li title="Editar mis datos personales" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/info">
                <FontAwesomeIcon icon={faUser} />
                <span className="text-large color-63 text-center ml-4">Datos personales</span>
              </Link>
            </div>
        </li> 
        <li title="Credencial digital" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/credencial">
                <FontAwesomeIcon icon={faIdCard} />
                <span className="text-large color-63 text-center ml-4">Credencial</span>
              </Link>
            </div>
        </li>  
        <li title="Ver mis iglesias y ministerios" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/listado/iglesias">
                <FontAwesomeIcon icon={faChurch} />
                <span className="text-large color-63 text-center ml-4">Iglesias y ministerios</span>
              </Link>
            </div>
        </li>  */}
        <li title="Ver mi cuenta" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/cuenta">
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                <span className="text-large color-63 text-center ml-4">Mi cuenta</span>
              </Link>
            </div>
        </li>   
        <li title="Ver aportantes" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/aportantes">
                <FontAwesomeIcon icon={faUsers} />
                <span className="text-large color-63 text-center ml-4">Mis aportantes</span>
              </Link>
            </div>
        </li>  
        <li title="Ver mis iglesias y ministerios" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/fondos">
                <FontAwesomeIcon icon={faCoins} />
                <span className="text-large color-63 text-center ml-4">Mis fondos</span>
              </Link>
            </div>
        </li> 
        {id_autorizados.some(item=>item==usuario.id_misionero) && <li title="Cargar informe de egresos" className="listado-al">
            <div className="text-centerxxx relative"> 
              <Link className="Nav__link"  to="/egresos">
                <FontAwesomeIcon icon={faPlane} />
                <span className="text-large color-63 text-center ml-4">Informe de Egresos</span>
              </Link>
            </div>
        </li>    } 
        {id_autorizados.some(item=>item==usuario.id_misionero) && <li title="Cargar informe de ingresos" className="listado-al">
            <div className="text-centerxxx relative"> 
              <Link className="Nav__link"  to="/ingresos">
                <FontAwesomeIcon icon={faChartLine} />
                <span className="text-large color-63 text-center ml-4">Informe de Ingresos</span>
              </Link>
            </div>
        </li>    } 
        {false && <li title="ir al inicio" className="listado-al">
            <div className="text-centerxxx"> 
              <Link className="Nav__link"  to="/home">
                <FontAwesomeIcon icon={faDoorOpen} />
                <span className="text-large color-63 text-center ml-4">Inicio</span>
              </Link>
            </div>
        </li>}                 
        <li title="Salir" className="listado-al p-4">
                 <div className="text-center"> 
                <div className="mr-auto ml-auto w-100" title="Salir" onClick={()=>logout()}>
                  <FontAwesomeIcon icon={faPowerOff} />
                  <span className="text-large color-63 block">Salir</span>
                </div>
            </div>
        </li>
        
        </ul>
    </div>

  )
}

function SeleccionarComponenteModal({componente}){
 
  switch(componente){
    case  'aulas' : return null
    default: return null
  }
}