import React,{useState,useEffect} from 'react'
import {useAlumno} from '../../../Context/alumnoContext'
import {formatearMontoMoneda,revertirMontoMoneda,seleccionarTextoInput} from '../../../Helpers/utilidades-globales'
import Axios from 'axios'
const regex_solo_numeros = /^[0-9]+([.])?([0-9][0-9]?)?$/;

export const useIngresos = ()=>{
    const {usuario,esCelular} = useAlumno()

    const [ingreso,setIngreso] = useState({
        id:-1,
        id_donante:null,             // los donantes pueden tener id negativo por eso no usamos el -1 como null
        mes:mesActual(),
        anio:crearVectorAnios()[0],
        gira:false,
        descripcion:'',
        id_estado_ingreso:-1,
        observaciones:'',
        monto:'0,00'
    })

    const descripcion  = 'Ingreso'
    const [ingresoString,setIngresoString] = useState('')
    const [donanteString,setDonanteString] = useState('')
    const [procesando,setProcesando] = useState(false)
    const [finalizado,setFinalizado] = useState(false)
    const [ingresos,setIngresos] = useState([])
    const [filtros,setFiltros] = useState({})
    const [periodos,setPeriodos] = useState({})
    const [conceptos,setConceptos] = useState([])
    const [estados,setEstados] = useState([])
    const [donantes,setDonantes] = useState([])
    const [solicitarDonante,setSolicitarDonante]=useState(false)
    const [file,setFile]=useState({})
    const [montoFormateado,setMontoFormateado]=useState(null)

    useEffect(()=>{
        buscarConceptos()
        buscarIngresos()
        buscarDonantes()
        buscarJson()

    },[])

    useEffect(()=>{
        setMontoFormateado(isNaN(Number(ingreso.monto))? '' : `$ ${formatearMontoMoneda(Number(ingreso.monto))}`)
    },[ingreso.monto])

      const reinicializar = ()=>{
        setIngreso({ id:-1,
            id_donante:null,             // los donantes pueden tener id negativo por eso no usamos el -1 como null
            mes:mesActual(),
            anio:crearVectorAnios()[0],
            gira:false,
            descripcion:'',
            id_estado_ingreso:-1,
            observaciones:'',
            monto:'0,00'})

            setFinalizado(false)
            setProcesando(false)
            setSolicitarDonante(false)
    }

    const nuevoDonante = (id_nuevo_donante,descripcion) =>{
        setIngreso({...ingreso,id_donante:Number(id_nuevo_donante)})
        setDonanteString(descripcion)
    }

    const borrarDonante = () =>{
        setIngreso({...ingreso,id_donante:null})
        setDonanteString('')
    }
    
    const handleChangeDonante = (e,value)=>{
        // este handle esta hecho para funcionar tanto con un Autocomplete de mui
        // como un select html o de mui
        let ingresoStringAux = ''
        if(value){
            if(typeof(value)=='string'){ // si viene de un select
                setDonanteString(value)
            }else if(typeof(value)=='object'){ // si viene de un autocomplete
                setDonanteString(value.label)
            }
        }else{
            setDonanteString('')
        }

        if(e.target?.value){
            setIngreso({...ingreso,id_donante:Number(e.target.value)})
        }else if (value?.id){
            setIngreso({...ingreso,id_donante:Number(value.id)})
        }else{
            setIngreso({...ingreso,id_donante:null})
        }
    }

    const handleChangeTipoSalida = (e,value)=>{
        // este handle esta hecho para funcionar tanto con un Autocomplete de mui
        // como un select html o de mui
        let ingresoStringAux = ''
        setDonanteString('')

        if(value){
            if(typeof(value)=='string'){ // si viene de un select
                setIngresoString(value)
            }else if(typeof(value)=='object'){ // si viene de un autocomplete
                setIngresoString(value.label)
            }
        }else{
            setIngresoString('')
        }

        if(e.target?.value){
            setIngreso({...ingreso,id:Number(e.target.value),id_donante:null})
            if(Number(e.target.value)==1){ // tipo ingreso = 1 ofrenda recibida y requiere que se indique un donante
                setSolicitarDonante(true)
            }else{
                setSolicitarDonante(false)
            }

        }else if (value?.id){
            setIngreso({...ingreso,id:Number(value.id),id_donante:null})

            if(Number(value.id)==1){ // tipo ingreso = 1 ofrenda recibida y requiere que se indique un donante
                setSolicitarDonante(true)
            }else{
                setSolicitarDonante(false)
            }

        }else{
            setIngreso({...ingreso,id:-1,id_donante:null})
            setSolicitarDonante(false)
        }
    }

    const handleChangeMes = (e)=>{
        setIngreso({...ingreso,mes:Number(e.target.value)})
    }

    const handleChangeEstado = (e)=>{
        setIngreso({...ingreso,id_estado_ingreso:Number(e.target.value)})
    }

    const handleChangeAnio = (e)=>{
        const fecha = new Date()
        const anioActual = fecha.getFullYear()

        //Esta lógica se escribe para evitar que se seleccione un período mayor al
        // actual en base al año seleccionado y al mes.
        // En el componente de meses se deshabilitan los meses mayores al actual cuando
        // seleccionó el año en curso.
        // Pero hace falta esta lógica para controlar que sea correcto el perído cuando
        // cambió de año, por ejemplo si estamos en julio 2022 no puede seleccionar agosto 
        // en adelante para el año 2022 , pero si podría seleccionar agosto para el 2021
        // si luego de seleccionar agosto 2021 vuelve al año 2022 se aplica este control
        // y llevo el mes al actual programaticamente.

        if (anioActual==Number(e.target.value)){
            if(Number(ingreso.mes)>mesActual()){
                setIngreso({...ingreso,anio:Number(e.target.value),mes:mesActual()})
            }else{
                setIngreso({...ingreso,anio:Number(e.target.value)})
            }
        }else{
            setIngreso({...ingreso,anio:Number(e.target.value)})
        }

    }

    const handleChangeGira = (e)=>{
        setIngreso({...ingreso,gira:e.target.value=='G' ? 1 : 0})
    }

    const handleChangeMonto = (e)=>{

        if(Number(e.target.value)>1000000){
            return
         }

        if(regex_solo_numeros.test(e.target.value.trim()) || e.target.value.trim()=='')
        {
            setIngreso({...ingreso, monto:e.target.value.trim()=='' ? '' : e.target.value})
        }

    }

    const handleChangeDescripcion = (e)=>{
        const descripcion = e.target.value.toUpperCase()
        setIngreso({...ingreso, descripcion:descripcion})
        setIngresoString(descripcion)
    }

    const datosOk = ()=>{


        if (ingreso.id==-1){
            return [false,'Falta seleccionar el concepto a informar']
        }

        if (ingreso.id==1 && !ingreso.id_donante){             // los donantes pueden tener id negativo por eso no usamos el -1 como null
            return [false,'El tipo de ingreso requiere que especifique un donante']
        }

        if (ingreso.id!=1 && ingreso.id_donante){
            return [false,'El tipo de ingreso no requiere un donante']
        }

        if (ingreso.mes==-1){
            return [false,'Falta seleccionar un mes']
        }

        if (!ingreso.monto){
            return [false,'Falta ingresar un monto']
        }

        if (Number(revertirMontoMoneda(ingreso.monto))==0){
            return [false,'Falta ingresar un monto']
        }

        if (ingreso.observaciones==undefined || !ingreso.observaciones){
            return [false,'Falta ingresar una observación']
        }

        if (ingreso.id_estado_ingreso==-1){
            return [false,'Falta seleccionar el estado del ingreso']
        }

        return [true,'']
    }

    const handleChangeOtroEgreso = (e)=>{
        alert('Se solicita otro concepto de salida')
    }

    const handleChangeObservaciones = (e)=>{
        const observaciones = e.target.value.toUpperCase()
        setIngreso({...ingreso, observaciones:observaciones})
    }

    const enviarInforme = async (values)=>{

        try{

            setProcesando(true)
            const montoMoneda =  Number(revertirMontoMoneda(ingreso.monto))
            
            const giraBoolean = ingreso.id==1 ? ingreso.gira==1 ? true :  false : false // si el tipo de ingreso es 1 o sea ofrenda recibida informo en gira o en campo según lo que indica el usuario, caso contrario (si no es 1 si no es ofrenda) siempre es en campo
            // los donantes pueden tener id negativo por eso no usamos el -1 como null

           // const id_donante_aux = ingreso.id_donante == -1 ? null : Number(ingreso.id_donante)

            const objetoaEnviar = {...ingreso,gira: giraBoolean ,monto:montoMoneda}
            
            const respuesta = await Axios.post(`/api/tablasgenerales/formulario/ingreso/${usuario.id_misionero}`,objetoaEnviar)
        
            setTimeout(() => {
                setProcesando(false)
                setFinalizado(true)
                setFiltros({})
                reinicializar()
                buscarIngresos()
            }, 1000);

            return 'El movimiento se grabó correctamente'
        }catch(err){
            setProcesando(false)
            if(err.response?.data?.message){
                    alert(err.response.data.message)
            }else{
                alert('Se produjo un error al grabar el movimiento')
            }
            return 'Se produjo un error al grabar el movimiento'
        }

    }

    const detalles = ()=>{
        return [
                ingreso.id==1 ? 'Movimiento en campo' : ingreso.gira ? 'Movimiento de ingreso en gira' : 'Movimiento de ingreso en campo', // si el tipo de ingreso = 1 o sea ofrenda recibida informamos gira o campo según lo que indicó el usuario, caso contrario al grabar seteamos en campo, no lo elige el usuario
                ingresoString,
                donanteString!='' ? `Donante: ${donanteString}` : '',
                `${mesString(ingreso.mes)} ${ingreso.anio}`,'  ',
                `$ ${ingreso.monto}`,
                `Observaciones: ${ingreso.observaciones}`,
                `Estado: ${estados.filter(item=>item.id==ingreso.id_estado_ingreso)[0].nombre}`
            ]
    }

    const resetearDescripcion = () => {
            setIngreso({...ingreso,id:-1,descripcion:''});
    };

    const buscarIngresos = async ()=>{
        try{
            const {data} = await Axios.get(`/api/tablasgenerales/formularios/ingresos/${usuario.id_misionero}`)
            setIngresos(data)
            cargarPeriodos(setPeriodos,data)
        }catch(err){
            console.log(err)
            alert('Se produjo un error al buscar el listado de movimientos')
        }
    }

    const buscarDonantes = async ()=>{
        try{
//            const {data} = await Axios.get(`/api/tablasgenerales/aportantes/${usuario.id_misionero}`)
            const {data} = await Axios.get(`/api/tablasgenerales/donantes`)
            setDonantes(data)
        }catch(err){
            console.log(err)
            alert('Se produjo un error al buscar el listado de donantes')
        }
    }

    const buscarJson = async ()=>{
        try{
//            const {data} = await Axios.get(`/api/tablasgenerales/testfile`)
            //const {data} = await Axios.get(`https://drive.google.com/file/d/1VOmzTxopI5QPZrhHBfhahGDTCanrdnz6/view?usp=sharing`)
           // const {data} = await fetch('https://drive.google.com/file/d/1VOmzTxopI5QPZrhHBfhahGDTCanrdnz6/view?usp=sharing')

          //  setFile(data) // estoy recibiendo un buffer y necesito recibir un json
        
            setFile({id:1,nombre:'prueba'})
        }catch(err){
            console.log(err)
            alert('Se produjo un error al buscar el listado de donantes')
        }
    }

    const buscarConceptos = async ()=>{
        try{
//            const {data} = await Axios.get(`/api/tablasgenerales/tipos_ingreso`)
            const datos = await Promise.all([Axios.get(`/api/tablasgenerales/tipos_ingreso`),
            Axios.get(`/api/tablasgenerales/estados_ingreso`)]) 
            setConceptos(datos[0].data)
            setEstados(datos[1].data)
        }catch(err){
            console.log(err)
            alert('Se produjo un error al buscar los conceptos y tipos de ingreso')
        }
    }

    
   const filtrar = (itemdelListado,filtros)=>{
        // esta función actúa en el metodo filter de un array.
        // cuando devuelve true se incluye el item que se está evaluando
        // si devuelve false no se incluye
        // voy a comparar un item contra un objeto
    
        // el item tiene este formato 
        //{id,fecha,id_tipo_salida,monto,mes,anio,clase,descripcion,nombre,mesString} ... cuando es un listado de egresos
        //                 *                   *     *                       *          ... marqué los campos que se evaluan si están presentes en el objeto de filtros
        //{id,fecha,id_tipo_ingreso,monto,mes,anio,clase,nombre,id_donante,mesString}  ... cuando es un listado de ingresos
        //                 *                   *     *                       *          ... marqué los campos que se evaluan si están presentes en el objeto de filtros
        // el filtro tiene este formato = {clase:'Gira',id:5,anio:2022,mesString:'Enero'} ... los campos de este objeto se agregan si no existe, se pisa si existe y se borra dependiendo de si el usuario eligió el filtro o no, cuando lo elige lo agregamos, cuando lo quita lo borramos del objeto filtro
        
        const arrayObjeto = Object.entries(filtros) // primero transformo el objeto filtro en un array tipo [['clase','Gira'],['id',5]...]
                                                    // es decir arrayOjbeto tiene en cada posicion un vector[0]=clave y vector[1]=valor
        let resultado = true

        // aprovechamos que se puede referenciar el campo de un objeto como un vector objeto['campo']

        // por cada item del vector de filtros evalúo si el valor del campo del item es diferente al valor del filtro
        // por ejemplo si el objeto filtro tiene {anio:2005,mesString:'Enero'}
        // se transforma a vector y queda [['anio':2005],['mesString':'Enero']]
        //         
        
        arrayObjeto.forEach((itemdelvectorFiltro,index)=>{
            if(itemdelListado[itemdelvectorFiltro[0]]!==undefined){ // evalúo si es igual o diferente solo si el campo existe, esto es porque se usa la misma función de filtrado para diferentes informes y puede haber primary keys que en algunos listados estén y en otros no, es importante este primer IF, por ejemplo id_tipo_salida no va a existir en un listado de ingresos por eso no habíra que evaluarlo aquí
                if(itemdelListado[itemdelvectorFiltro[0]]!=itemdelvectorFiltro[1] && itemdelvectorFiltro[0]!='observaciones' ){
                            resultado = false
                }
                if(itemdelvectorFiltro[0]=='observaciones' && !(itemdelListado[itemdelvectorFiltro[0]]?.toUpperCase()?.includes(itemdelvectorFiltro[1].toUpperCase()))){
                    resultado = false
                }
            }
        })

       return resultado
    }

    const formatearMontoBlur = ()=>{
        setIngreso({...ingreso, monto:formatearMontoMoneda(Number(ingreso.monto))})
    }

    const formatearMontoFoco = (e)=>{
        setIngreso({...ingreso, monto:ingreso.monto=='0,00' ? '' : revertirMontoMoneda(ingreso.monto)})      
    }

  
    return {ingreso,handleChangeTipoSalida,
            handleChangeAnio,
            handleChangeMes,
            handleChangeGira,
            handleChangeDescripcion,
            handleChangeMonto,
            datosOk,
            enviarInforme,
            detalles,handleChangeOtroEgreso,
            resetearDescripcion,crearVectorAnios,
            ingresoString,
            procesando,
            finalizado,
            reinicializar,
            buscarIngresos,
            ingresos:ingresos.filter(item=>filtrar(item,filtros)).filter(item=>filtros?.asignado==undefined ? true : filtros?.asignado==true ? item.recibo : !item.recibo),
            setFiltros,filtros,
            id_misionero: usuario.id_misionero,
            periodos,
            formatearMontoBlur,
            formatearMontoFoco,
            descripcion,
            conceptos,
            donantes,
            handleChangeDonante,
            donanteString,
            buscarDonantes,
            solicitarDonante,solicitarDonante,nuevoDonante,borrarDonante,montoFormateado,
            esCelular,handleChangeObservaciones,handleChangeEstado,
            estados}
}

const mesActual = ()=>{
    const fecha = new Date()
    const mes = fecha.getMonth()

    return mes + 1
}

const mesString = (mes)=>{

    switch(mes){
        case  1 : return 'Enero'
        case  2 : return 'Febrero'
        case  3 : return 'Marzo'
        case  4 : return 'Abril'
        case  5 : return 'Mayo'
        case  6 : return 'Junio'
        case  7 : return 'Julio'
        case  8 : return 'Agosto'
        case  9 : return 'Septiembre'
        case  10 : return 'Octubre'
        case  11 : return 'Noviembre'
        default: return 'Diciembre'
    }

}

const crearVectorAnios = ()=>{
    const fecha = new Date()
    const anio = fecha.getFullYear()
    const anios = []

    for (let i=anio;i>anio-5;i--){
        anios.push(i)
    }

    return anios
}

const cargarPeriodos = (setPeriodos,data)=>{
    const periodos = new Map()

    data.forEach(item=>{
        const periodo = `${item.mesString} ${item.anio}`
        const objetoPeriodo = {mes:item.mesString,anio:item.anio}
        periodos.set(periodo,objetoPeriodo)
    })

    const mapPeriodosArray = Array.from(periodos.entries())
    const vectorPeriodos = mapPeriodosArray.map(item=>{return {id:item[1],label:item[0]}})
    
    setPeriodos(vectorPeriodos)
}