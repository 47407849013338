import React, {useContext,useEffect,useState} from 'react'
import {LinearProgress, CircularProgress, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FiltrosEgresos } from './FiltrosEgresos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUser,faEdit } from '@fortawesome/free-regular-svg-icons';
import { faQrcode, faToggleOn } from '@fortawesome/free-solid-svg-icons';

export const ListadoFormularios = ({contexto})=>{
    const {movimientos,filtros,descripcion} = useContext(contexto)
    const [cargando,setCargando]=useState(true)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    useEffect(()=>{
      setTimeout(() => {
          setCargando(false)//simulamos una carga asincrónica...lo correcto sería traer una propiedad "cargando" del contexto
      }, 800);
    })

    const classes = useStyle();

    if(cargando){
       return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'200px' }}> 
          <CircularProgress title='Cargando...'/>
          <Typography variant='bpdy2'>
                   Cargando...
        </Typography>
      </Box>
    }

    if((!movimientos || movimientos?.length==0) && noHayFiltros(filtros)){ // si hay filtros puede ser que no haya items porque no hubo match con los filtros entonces muestro la lista aunque esté vacía para que el usuario pueda modificar los filtros
      return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'200px' }}> 
        <Typography variant='h6'>
            No tiene movimientos cargados
        </Typography>
      </Box>
    }


    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
    <FiltrosEgresos contexto={contexto}/>
          {movimientos.length == 0 &&  <Box  sx={{marginTop:'1.5rem'}}><Typography variant='h6'>
              {`No se encontraron movimientos ${!noHayFiltros(filtros) ? 'que coincidan con el filtro' : ''}`}
          </Typography>
          </Box>}
    {movimientos.length > 0 && <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className={classes.select}>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Concepto</TableCell>
            <TableCell align="left">Período</TableCell>
            <TableCell align="left">Categoría</TableCell>
            {descripcion.toUpperCase()=='INGRESO' && <TableCell align="left">Donante</TableCell>}
            <TableCell align="right">Monto</TableCell>
            <TableCell align="left">Fecha</TableCell>
            <TableCell align="left">Observaciones</TableCell>
            {descripcion.toUpperCase()=='INGRESO' && <TableCell align="left">Estado</TableCell>}
            {descripcion.toUpperCase()=='INGRESO' && <TableCell align="left">Recibo</TableCell>}
            {descripcion.toUpperCase()=='EGRESO' && <TableCell align="left">Retirado</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {movimientos.map((row) => (
            <TableRow
              key={`lfe-${row.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell component="tsh" scope="row">
                {row.nombre}
                <Box>
                    {row.descripcion? row.descripcion : ''}
                </Box>
              </TableCell>
              <TableCell align="left">{row.mesString} {row.anio}</TableCell>
              <TableCell align="left">{row.clase}</TableCell>
              {descripcion.toUpperCase()=='INGRESO' && <TableCell align="left">{row.donante}</TableCell>}
              <TableCell align="right">{row.monto}</TableCell>
              <TableCell align="left">{row.fecha}</TableCell>
              <TableCell align="left" title={row?.observaciones} sx={{fontSize:'small'}}>{formatearObs(row?.observaciones)}</TableCell>
              {descripcion.toUpperCase()=='INGRESO' && <TableCell align="left">{row?.estado}</TableCell>}
              {descripcion.toUpperCase()=='INGRESO' && <TableCell align="left">{row?.recibo}</TableCell>}
              {descripcion.toUpperCase()=='EGRESO' && <TableCell align="left">{row?.retirado ? <FontAwesomeIcon title='Retirado' className='text-large color-red' icon={faToggleOn} />:''}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>}
  </Box>
}

const noHayFiltros = (filtros)=>{
  return Object.keys(filtros).length==0
}

const formatearObs = (observaciones)=>{
  if(observaciones){
      if(observaciones.length>50){
        return `${observaciones.substring(0,50)}...`
      }else{
        return observaciones
      }
  }else{
    return ''
  }
}