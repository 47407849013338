import React, {useContext,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {GenericCard} from '../../../componentes/MUI/GenericCard'
import {SelectTiposIngreso} from '../componentes/SelectTiposIngreso'
import {SelectMes} from '../componentes/SelectMes'
import {SelectAnio} from '../componentes/SelectAnio'
import {InputMonto} from '../componentes/InputMonto'
import {RadioGiraCampo} from '../componentes/RadioGiraCampo'
import {AutocompleteTipoIngreso} from '../componentes/AutocompleteTipoIngreso'
import {ButtonEnviar} from '../componentes/ButtonEnviar'
import {ingresoContext} from '../contexto/ingresoContext'
import { ListadoFormularios } from '../componentes/ListadoFormularios';
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';


export const Ingresos = ({usuario})=>{

    const {egreso,datosOk,procesando,finalizado,reinicializar} = useContext(ingresoContext)
    const [comenzar,setComenzar] = useState(false)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    const cancelar = ()=>{setComenzar(false);reinicializar()}

    if (procesando){
        return  <Box style={{display:'flex',justifyContent:'center',textAlign:'center'}}>
                <GenericCard titulo="..." 
                     id='tl-finalizar' 
                     mostrar={true}
                     error={true}
                     mostrarLoading
                     mensajeError = {'Procesando la solicitud...'}
                     >
                 </GenericCard>
             </Box>
     }

     if (finalizado){
        return  <Box style={{display:'flex',flexDirection:'column',textAlign:'center', justifyContent:'center'}}>
            
            <GenericCard titulo="..." 
                     id='tl-finalizar' 
                     mostrar={true}
                     error={false}
                     subtitulo='El egreso se informó correctamente'
                     >
                          <Button 
                            onClick={reinicializar} 
                            variant="outlined" color='primary' size='large'>
                                Informar otro ingreso
                        </Button> 
             </GenericCard>
            <ListadoFormularios/>
             </Box>
     }

    return  <Box style={{display:'flex',flexDirection:'column',justifyContent:'center',textAlign:'center'}}>
            {comenzar && <GenericCard
                            id='tl-contacto' 
                            mostrar={true}
                            estilo={{maxWidth:'300px',marginLeft:'auto',marginRight:'auto'}}
                            error={!datosOk()[0]}
                            mensajeError = {datosOk()[1]}>
                    {comenzar && 
                            <IconButton aria-label="Cancelar"  onClick={cancelar}>
                                    <CloseIcon sx={{border:'1px solid red',borderRadius:'50%',color:'red'}} />
                            </IconButton>
                    }

                    <SelectTiposIngreso/> 
                    <Box sx={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                        <SelectMes/>
                        <SelectAnio/>
                    </Box>
                    <RadioGiraCampo/>
                    <InputMonto/>
                    {datosOk()[0] && <ButtonEnviar/> } 
             </GenericCard>}
             <GenericCard
                            id='tl-contacto' 
                            mostrar={true}
                            column>
                    {!comenzar && <Button 
                                    onClick={()=>setComenzar(true)} 
                                    variant='contained' color='primary' size='large'>
                                        Informar un ingreso
                    </Button>} 
                    <ListadoFormularios/>
            </GenericCard>
            <AlertDialog open={false} 
                    titulo='Especifique un nuevo tipo de ingreso' 
                    subtitulo='Ingreso nuevo' 
                    procesarCancelar = {()=>console.log('pp')}
                    procesarResultado={()=>console.log('pp')}
                    error={''}>
                            <GenericCard
                            id='tl-contacto' 
                            mostrar={true}
                            column
                            error={!datosOk()[0]}
                            mensajeError = {datosOk()[1]}>
                    <SelectTiposIngreso/> 
                    <Box sx={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                        <SelectMes/>
                        <SelectAnio/>
                    </Box>
                    <RadioGiraCampo/>
                    <InputMonto/>
                    {datosOk()[0] && <ButtonEnviar/> }
             </GenericCard>
                </AlertDialog>
        </Box>
}
