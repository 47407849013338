
import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Main from './Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faTimes } from '@fortawesome/free-solid-svg-icons';
import Seleccionador from './Seleccionador'
import usePaginacion from '../hooks/usePaginacion';
import Paginacion from '../componentes/Paginacion';
import Modal from '../componentes/Modal';
import useModal from '../hooks/useModal';

export default function Cuenta({usuario}){

const [datos,setDatos] = useState([])
const [donante,setDonante] = useState(null)
const [infoDonante,setInfoDonante]= useState([])    
const [movDonante,setMovDonante] = useState([])
const [listado,setListado] = useState([])
const [donanteSeleccionado,setDonanteSeleccionado] = useState(null)
const [status,setStatus] = useState(null)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [buscandoDonante,setBuscandoDonante] = useState(false)
const [verQr,setVerQr] = useState(false)
const {configuracion,paginar,inicializar} = usePaginacion();
const anchoPaginacion = 50;
const {toggle, isShowing } = useModal();

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/aportantes/${usuario.id_misionero}`)]) 
            setBuscandoDatos(false)
            return vectorResultado[0].data

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()
    .then(listado=>{
        setListado(listado)
    })               
},[])

/*
useEffect(()=>{
    const obtenerDatos = async ()=>{
        try{

            const url_movs = `/api/tablasgenerales/movaportante/${usuario.id_misionero}/${donanteSeleccionado}`
            const url_info = `/api/tablasgenerales/aportante/${donanteSeleccionado}`

            const vectorResultado = await Promise.all([Axios.get(url_movs),Axios.get(url_info)]) 
            setMovDonante(vectorResultado[0].data)
            setDonante(vectorResultado[1].data)

        }catch(err){
            console.log(err)
        }
    }
    if(donanteSeleccionado){
        obtenerDatos()
    }
                 
},[donanteSeleccionado])*/

useEffect(()=>{
    inicializar(anchoPaginacion,listado)
},[listado])

useEffect(()=>{
    if(donanteSeleccionado){
        buscarDonante()
    }
},[donanteSeleccionado])

const buscarDonante = async ()=>{

    setBuscandoDonante(true)

    const url_movs = `/api/tablasgenerales/movaportante/${usuario.id_misionero}/${donanteSeleccionado}`
    const url_info = `/api/tablasgenerales/aportante/${donanteSeleccionado}`

    try{
        const vectorResultados = await Promise.all([Axios.get(url_movs),Axios.get(url_info)])
        //console.log(vectorResultados)
        setDonante(vectorResultados[0].data)
        setInfoDonante(vectorResultados[1].data)
        setBuscandoDonante(false)
       // return vectorResultados[0].data
    }catch(err){
        console.log('Error al buscar los movimientos de un donante')
        setBuscandoDonante(false)
       // setHuboError(true)
        return 
    }

}

    if (buscandoDatos){
        return <Main center alinear><div className="cont-loading"><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    };    


return <Main center>
            <div>
            <Paginacion configuracion={configuracion} paginar={paginar}/>
            {/*donante && <div style={{width:"80%"}} className="border-dotted-gray bg-white fixed ml-auto mr-auto w-400 right-0 left-0">
                <button onClick={()=>setDonante(null)}>Cerrar</button>
                <Donante donante={donante} infoDonante={infoDonante} cerrar={()=>setDonante(null)}/>                                                    
            </div>*/}
        { isShowing && donanteSeleccionado && <Modal hide={toggle} 
                                             isShowing={isShowing} 
                                             titulo={"Aportante"}
                                             estiloWrapper={{background:'#000000bf'}}
                                             closeOnclickOutside={true}>
                <Donante donante={donante} infoDonante={infoDonante} cerrar={()=>setDonanteSeleccionado(null)} buscandoDonante={buscandoDonante}/>                                                    
            {/*<OtrasIglesiasObrero obrero={obreroSeleccionadoCredencial}/>*/}             
        </Modal>}  
            <table className="table2 mt-8 table-cent" style={{width:'70%'}}>
            <thead>
                <tr>
                    <th className="text-left a-col1" >Nombre</th>
                </tr>
            </thead>
            <tbody>
            {
                listado
                .map((item,index)=>{return {...item,indice:index+1}})
                .filter((item,index)=>{
                    return index>= configuracion.iIni && index<=configuracion.iFin
                })
                .map(curso => {
                return (
                    <tr onClick={()=>{{toggle();setDonanteSeleccionado(curso.id_donante)}}} key={curso.id_donante} className="border-bottom-solid cursor-pointer">
                         <td className="w-3r a-col1">{curso.donante}</td>
                    </tr>
                )
                })
            }
            </tbody>
        </table>
        </div>
</Main>
return <Main center>
     <div>
         {listado.map(item=><div><p onClick={()=>setDonanteSeleccionado(item.id_donante)}>{JSON.stringify(item)}</p>
            {donanteSeleccionado==item.id_donante && <div>
                    <p className="bg-green">{JSON.stringify(donante)}</p>
                </div>}
            {donanteSeleccionado==item.id_donante && <div>
                    {movDonante.map(item=><p className="bg-red">{JSON.stringify(item)}</p>)}
                </div>}
         </div>)}
     </div>
</Main>
    


}


function Donante_old({donante,infoDonante,buscandoDonante}){

    if (buscandoDonante){
        return <div className="cont-loading"><Loading center/><span className="cargando">Buscando donante...</span></div>
    } else{
    return <div className="FormAbmContainer flex f-col ml-2">
    {!infoDonante && <div className="cont-loading"><Loading center/><span className="cargando">Buscando donante...</span></div>}
              {/*<div className="flex f-col">
                  <span className="p-2 mb-2 text-white bg-tomato inline-block-1 text-center">Balances</span>
                  {balances.map(item=><span className={item.estado ==0 ? 'bal-np' : 'bal-pr'}>{item.periodo}</span>)}
              </div>*/}
             <span title="Donante" className="border-bottom-dotted-gray cursor-pointer"><span className="color-gray text-smaller ml-2 mr-2">Aportante</span>{infoDonante.donante}
             </span>
             <span title="E-mail" className="border-bottom-dotted-gray cursor-pointer"><span className="color-gray text-smaller ml-2 mr-2">Contacto</span>{infoDonante.email != "" ? infoDonante.email : 'No posee email'}
             </span>

              {donante.length > 0 && <div className="flex f-col text-large mt-4">

              {/* !verDetalle && <span onClick={switchDetalle} className="cursor-pointer botonNc text-small color-gray" >
                          <FontAwesomeIcon onClick={switchDetalle} className="color-tomato" icon={faPlus}/> Ver el detalles de balances y diezmos
              </span>*/}  
              {/* verDetalle &&<span onClick={switchDetalle} className="cursor-pointer botonNc text-small color-gray" >
                          <FontAwesomeIcon onClick={switchDetalle} className="color-tomato" icon={faMinus}/> Ocultar el detalles de balances y diezmos
              </span>*/ }     
                <table>
                    <thead>
                            <tr>
                                <td className="filas-lista-nw ti-edad color-gray">Año</td>
                                <td className="filas-lista-nw ti-edad color-gray">Mes</td>
                                <td className="filas-lista-nw ti-monto-title color-gray" >Aporte acumulado</td>
                                <td className="filas-lista-nw ti-monto-title color-gray">Aporte mensual</td>
                                <td className="filas-lista-nw ti-monto-title color-gray">Aporte total</td>
                            </tr>
                    </thead>    
                    <tbody>
                          
                    {donante.map(item=>{
                       return <tr>
                            <td className="filas-lista-nw ti-edad">{item.año}</td>
                            <td className="filas-lista-nw ti-edad">{item.mes}</td>
                            <td className="filas-lista-nw ti-monto">{item.acum.toFixed(2)}</td>
                            <td className="filas-lista-nw ti-monto">{item.disponible.toFixed(2)}</td>
                            <td className="filas-lista-nw ti-monto">{item.total.toFixed(2)}</td>
                        </tr>
                    })
                    }
                  </tbody>
                  </table>
              </div>}
              {donante.length == 0 && <div className="flex f-col text-large">
                  <div className="ig-min"><span>{`No se cargaron movimientos del donante`}</span></div>
          </div>}                 
      </div>
    }
}

function Donante({donante,infoDonante,buscandoDonante}){

    if (buscandoDonante || !donante){
        return <div className="cont-loading"><Loading center/><span className="cargando">Buscando donante...</span></div>
    } else{
    return <div className="FormAbmContainer flex f-col ml-2">
    {!infoDonante && <div className="cont-loading"><Loading center/><span className="cargando">Buscando donante...</span></div>}
              {/*<div className="flex f-col">
                  <span className="p-2 mb-2 text-white bg-tomato inline-block-1 text-center">Balances</span>
                  {balances.map(item=><span className={item.estado ==0 ? 'bal-np' : 'bal-pr'}>{item.periodo}</span>)}
              </div>*/}
             <span title="Donante" className="border-bottom-dotted-gray cursor-pointer"><span className="color-gray text-smaller ml-2 mr-2">Aportante</span>{infoDonante.donante}
             </span>
             <div title="E-mail" className="border-bottom-dotted-gray cursor-pointer"><span className="color-gray text-smaller ml-2 mr-2">Contacto</span>{infoDonante.email != "" && infoDonante.email != null ? <a target="_blank" href={crearMailToIndividual(infoDonante.email)} >{infoDonante.email}</a>: <span>No posee e-mail</span>} 
             </div>
             

              {donante.length > 0 && <div className="flex f-col text-large mt-4">

              {/* !verDetalle && <span onClick={switchDetalle} className="cursor-pointer botonNc text-small color-gray" >
                          <FontAwesomeIcon onClick={switchDetalle} className="color-tomato" icon={faPlus}/> Ver el detalles de balances y diezmos
              </span>*/}  
              {/* verDetalle &&<span onClick={switchDetalle} className="cursor-pointer botonNc text-small color-gray" >
                          <FontAwesomeIcon onClick={switchDetalle} className="color-tomato" icon={faMinus}/> Ocultar el detalles de balances y diezmos
              </span>*/ }     
                               <table className="table2">
                    <thead>
                            <tr>
                                <td className="filas-lista-nw color-gray rec-cond-ls">Año</td>
                                <td className="filas-lista-nw color-gray rec-cond-ls">Mes</td>
                                <td className="filas-lista-nw ti-monto color-gray rec-cond-ls" >Acumulado</td>
                                <td className="filas-lista-nw ti-monto color-gray rec-cond-ls">Mensual</td>
                                <td className="filas-lista-nw ti-monto color-gray rec-cond-ls">Total</td>
                            </tr>
                    </thead>    
                    <tbody>
                          
                    {donante.map(item=>{
                       return <tr>
                            <td className="filas-lista-nw ti-monto rec-cond-ls0">
                                <div className="flex f-col items-flex-start border-bottom-solid">
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Año / Mes</span>{item.año} / {mesString(item.mes)}</p>
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Acumulado</span> {item.acum}</p>
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Mensual</span> {item.disponible}</p>
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Total</span>{item.total}</p>
                                </div>
                            </td>
                            <td className="filas-lista-nw rec-cond-ls">{item.año}</td>
                            <td className="filas-lista-nw rec-cond-ls">{mesString(item.mes)}</td>
                            <td className="filas-lista-nw ti-monto rec-cond-ls">{item.acum}</td>
                            <td className="filas-lista-nw ti-monto rec-cond-ls">{item.disponible}</td>
                            <td className="filas-lista-nw ti-monto rec-cond-ls">{item.total}</td>
                        </tr>
                    })
                    }
                  </tbody>
                  </table>

              </div>}
              {donante.length == 0 && <div className="flex f-col text-large">
                  <div className="ig-min"><span>{`No se cargaron movimientos del donante`}</span></div>
          </div>}                 
      </div>
    }
}

function mesString(mes){
    let string;

    switch(mes){
        case 1 : string = 'Enero';break;
        case 2 : string = 'Febrero';break;
        case 3 : string = 'Marzo';break;
        case 4 : string = 'Abril';break;
        case 5 : string = 'Mayo';break;
        case 6 : string = 'Junio';break;
        case 7 : string = 'Julio';break;
        case 8 : string = 'Agosto';break;
        case 9 : string = 'Septiembre';break;
        case 10 : string = 'Octubre';break;
        case 11 : string = 'Noviembre';break;
        case 12 : string = 'Diciembre';break;

    }

    return string 
}

function crearMailToIndividual(email){
    return email!=null && email!='' ? `mailto: ${email}` : ``
}