import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,InputAdornment,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import CheckIcon from '@mui/icons-material/CheckCircle';
import {CheckInputCelular} from './CheckInputCelular'

export const InputObservaciones = ({contexto})=>{
    const {movimiento,handleChangeObservaciones,formatearMontoBlur,formatearMontoFoco,montoFormateado,esCelular} = useContext(contexto)
    const [tieneFoco,setTieneFoco]=useState('')

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <Box sx={{marginTop:'1rem',display:'flex',width:'100%'}}>
                    <TextField fullWidth 
                        inputProps={{maxLength: 1000}} 
                        id="dp-monto" 
                        multiline
                        minRows='2'
                        style={movimiento.observaciones=='' ? {border: '1px solid gray',borderRadius:'5px'}:{}}
                        value={movimiento.observaciones} 
                        onChange={handleChangeObservaciones}
                       label="Observaciones" variant="outlined" />
                </Box>
            </Box>
}