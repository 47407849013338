import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faChevronCircleLeft, faMapMarkerAlt, faUndo } from '@fortawesome/free-solid-svg-icons';
import {Link,useHistory,useParams } from 'react-router-dom'
import {useAlumno} from '../Context/alumnoContext';
import Logo from './Logo'
import Cuenta from './Cuenta';
import Aportantes from './Aportantes';
import Fondos from './Fondos';
import Logs from './Logs';
//import {Egresos} from '../formularios/egresos/Egresos'
import {Egresos} from '../formularios/egresos/vistas/Egresos'
import {Ingresos} from '../formularios/ingresos/vistas/Ingresos'
import {FormularioContextProvider as ProviderEgreso} from '../formularios/egresos/contexto/egresoContext'
import {FormularioContextProvider as ProviderIngreso} from '../formularios/ingresos/contexto/ingresoContext'
import {Movimientos} from '../formularios2/vistasComunes/Movimientos'
import {FormularioContextProvider as ProviderEgreso2} from '../formularios2/egresos/contexto/egresoContext'
import {FormularioContextProvider as ProviderIngreso2} from '../formularios2/ingresos/contexto/ingresoContext'


export default function ContenedorVistas ({usuario,tipo}){

const [vistaCargada, setVistaCargada] = useState(false)
const [mostrarBotones,setMostrarBotones]=useState(true)
const {cargandoVista} = useAlumno()

   const history = useHistory();
   const parametros = useParams();

   const irHome = ()=>{
        history.push('/home')
   }

   const volver = ()=>{
        history.push(rutaDestino(tipo))
   }

   const mostrarBotonesVolver = (valor)=>{
        setMostrarBotones(valor)
   }

    return (
        <div className="w-cred">
            
            {tipo!='credencial' && <div onClick={irHome} className="flex justify-center items-center mt-2 cursor-pointer">
                <Logo width={"50"}/>
            </div>}
            {!cargandoVista && mostrarBotones && <div className="flex f-row justify-center items-center mt-4 mb-4">
                 <FontAwesomeIcon icon={faChevronCircleLeft} 
                                  className="cursor-pointer" 
                                  title="Volver"
                                  onClick={volver}/>
                 <span onClick={volver} className="cursor-pointer inline-block-1 titulo-cab-modal">
                                {titulo(tipo)}
                 </span>   
            </div>}
          
             {tipo=="cuenta" && 
                         <Cuenta usuario={usuario}
                         />  
             }  
             {tipo=="logs" && 
                         <Logs/>  
             }  
            {tipo=="aportantes" && 
                         <Aportantes usuario={usuario}
                         />  
             }  
            {tipo=="fondos" && 
                         <Fondos usuario={usuario}
                         />  
             }  
            {tipo=="egresos2" && 
                         <ProviderEgreso>
                            <Egresos usuario={usuario} mostrarBotonesVolver={mostrarBotonesVolver}/>
                         </ProviderEgreso>  
             }  
             {tipo=="ingresos2" && 
                         <ProviderIngreso>
                            <Ingresos usuario={usuario} mostrarBotonesVolver={mostrarBotonesVolver}/>
                         </ProviderIngreso>  
             }  
               {(tipo=="egresos" || tipo=='ingresos' ) && 
                         <ProviderIngreso2>  
                            <ProviderEgreso2>
                                <Movimientos tipo={tipo} usuario={usuario} mostrarBotonesVolver={mostrarBotonesVolver}/>
                            </ProviderEgreso2>  
                        </ProviderIngreso2>  

             }  

             {!cargandoVista && mostrarBotones && <div onClick={volver} className="cursor-pointer mt-4 mb-4 text-center flex f-row justify-center items-center">
                <FontAwesomeIcon icon={faChevronCircleLeft} 
                                    className=" text-center mt-4 mb-4 text-xxlarge color-tomato" 
                                    title="Volver"
                                    /> <span className="text-small ml-4">Volver</span>
             </div>}

        </div>
    )
}


function titulo(tipo){

    switch(tipo){
        case 'aportantes':
            return 'Mis aportantes';
        case 'egresos':
                return 'Informe de egresos';   
        case 'ingresos':
                    return 'Informe de ingresos';     
        case 'egresos2':
                return 'Egresos 2';   
        case 'ingresos2':
                    return 'Ingresos 2';                              
        case 'fondos':
            return 'Mis fondos';
        case 'cuenta':
                return 'Mi cuenta';  
        case 'logs':
                return 'Logs';                  
       default: return 'Desconocido'
    }

}



function rutaDestino(tipo){

    switch(tipo){
        case 'fondos':
        case 'cuenta':
        case 'aportantes':
        case 'egresos':
        case 'ingresos':
        case 'logs':
        return '/home';
        default:return '/home';
    }


}