import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {egresoContext} from '../contexto/egresoContext'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FiltrosEgresos } from './FiltrosEgresos';

export const ListadoFormularios = ()=>{
    const {egresos} = useContext(egresoContext)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
    <FiltrosEgresos/>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead className={classes.select}>
          <TableRow>
            <TableCell>Egreso</TableCell>
            <TableCell align="left">Período</TableCell>
            <TableCell align="left">Clase</TableCell>
            <TableCell align="right">Monto</TableCell>
            <TableCell align="left">Fecha</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {egresos.map((row) => (
            <TableRow
              key={`lfe-${row.id_solicitud_salida}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="tsh" scope="row">
                {row.nombre}
                <Box>
                    {row.descripcion? row.descripcion : ''}
                </Box>
              </TableCell>
              <TableCell align="left">{row.mesString} {row.anio}</TableCell>
              <TableCell align="left">{row.clase}</TableCell>
              <TableCell align="right">{row.monto}</TableCell>
              <TableCell align="left">{row.fecha}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Box>
}