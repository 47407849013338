import React, {useContext,useEffect,useState,useRef} from 'react'
import {TextField, Grid, Box,Typography,Paper,Button,makeStyles ,FormControlLabel,Switch,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import { AlertDialog } from '../../componentes/MUI/AlertDialog';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from '@mui/icons-material/Person';
import { getBottomNavigationActionUtilityClass } from '@mui/material';
import Swal from 'sweetalert2';
import {useAlumno} from '../../Context/alumnoContext'
import {CheckInputCelular} from './CheckInputCelular'

const regex_solo_numeros = /^[0-9\b]+$/;
const regex_telefono = /^[0-9-+()\b]+$/;

export const NuevoDonante = ({contexto,procesarCancelar,finalizarAlta})=>{
    const {movimiento,nuevoDonante,donantes,buscarDonantes} = useContext(contexto)
    const [datos,setDatos] = useState({nombre:'',
                                        apellido:'',
                                        direccion:'',
                                        institucion:'',
                                        particular:true,
                                        provincia:'',
                                        localidad:'',
                                        cod_postal:'',
                                        pais:'',
                                        telefono:'',
                                        celular:'',
                                        denominacion:'',
                                        email:'',
                                        observaciones:''
                                        })
    const [mensajeError,setMensajeError] = useState(null)
    const [verSimilitudes,setVerSimilitudes] = useState(false)
    const [abrirPrimeraPregunta,setAbrirPrimeraPregunta] = useState(true)
    const [resultado,setResultado] = useState(null)
    const {esCelular} = useAlumno()
    const [tieneFoco,setTieneFoco]=useState('')

    useEffect(()=>{
       setMensajeError(validarDatos())
    },[datos])

    const useStyle = makeStyles({
        selectpais: {
            background:datos.pais > 0 ? 'white' : 'gray',
            color:'black'
        },
        selectprovincia: {
            background:datos.provincia > 0 ? 'white' : 'gray',
            color:'black'
        },
      });

  


    const limpiarDatos = ()=>{
        setDatos({...datos,nombre:'',
                            apellido:'',
                            institucion:'',
                            direccion:'',
                            particular:true,
                            provincia:'',
                            localidad:'',
                            cod_postal:'',
                            pais:'',
                            telefono:'',
                            celular:'',
                            observaciones:'',
                            email:'',
                            denominacion:''})
        setResultado(null)
        //setAltaDonante(false)
    }

    const validarDatos = ()=>{

        if(datos.nombre.trim()==''){
            return ('Falta ingresar el nombre del donante')
        }

        if(datos.nombre.trim().length<3){
            return('El nombre es demasiado breve')
        }

        if(datos.apellido.trim()==''){
            return('Falta ingresar el apellido del donante')
        }

        if(datos.apellido.trim().length<3){
            return('El apellido es demasiado breve')
        }

        if(!datos.particular){
            if(datos.institucion.trim()==''){
                return('Falta ingresar el nombre de la iglesia')
            }

            if(datos.denominacion.trim()==''){
                return('Falta ingresar la denominación')
            }
        }

        if(datos.telefono.trim()==''){
            return('Falta ingresar el teléfono fijo')
        }

        if(datos.telefono.trim().length<8){
            return('El teléfono fijo es demasiado corto')
        }

        if(datos.celular.trim()==''){
            return('Falta ingresar teléfono móvi')
        }

        if(datos.celular.trim().length<8){
            return('El teléfono móvil es demasiado corto')
        }

        if(datos.email.trim()==''){
            return [false,'Falta completar el correo electrónico']
        }
    
        if(!validarEmail(datos.email)){
            return [false,'El e-mail ingresado parece ser inválido']
        }

        if(datos.direccion.trim()==''){
            return('Falta ingresar la dirección')
        }

        if(datos.direccion.trim().length<10){
            return('La dirección ingresada es demaiado breve. Al menos 10 carácteres')
        }

        if(datos.localidad.trim()==''){
            return('Falta ingresar la localidad')
        }

        if(datos.cod_postal.trim()==''){
            return('Falta ingresar el código postal')
        }

        if(datos.provincia.trim()==''){
            return('Falta ingresar la provincia')
        }

        if(datos.pais.trim()==''){
            return('Falta ingresar el país')
        }

        if(datos.pais.trim()==''){
            return('Falta ingresar el país')
        }

        return true

    }

    const handleChangeParticular = (e)=>{
        setDatos({...datos,particular:e.target.checked ? false : true, institucion:'',denominacion:''})
    }

    const handleChangeNombre = (e)=>{
        setDatos({...datos,nombre:e.target.value.toUpperCase().trim()})
    }

    const handleChangeApellido = (e)=>{
        setDatos({...datos,apellido:e.target.value.toUpperCase().trim()})
    }

    const handleChangeTelefono = (e)=>{
        if(regex_telefono.test(e.target.value.trim()) || e.target.value.trim()==''  || e.target.value.trim()=='-')
        {
            setDatos({...datos,telefono:e.target.value})
        }
    }

    const handleChangeEmail = (event) => {
        setDatos({...datos,email:event.target.value});
    };

    const handleChangeCelular = (e)=>{
        if(regex_telefono.test(e.target.value.trim()) || e.target.value.trim()==''  || e.target.value.trim()=='-')
        {
            setDatos({...datos,celular:e.target.value})
        }
    }

    const handleChangeInstitucion = (e)=>{
        setDatos({...datos,institucion:e.target.value.toUpperCase()})
    }

    const handleChangeObservaciones = (e)=>{
        setDatos({...datos,observaciones:e.target.value.toUpperCase()})
    }

    const handleChangeDireccion = (e)=>{
        setDatos({...datos,direccion:e.target.value.toUpperCase()})
    }

    const handleChangeLocalidad = (e)=>{
        setDatos({...datos,localidad:e.target.value.toUpperCase()})
    }

    const handleChangeCodPostal = (e)=>{
        setDatos({...datos,cod_postal:e.target.value.toUpperCase()})
    }

    const handleChangeProvincia = (e)=>{
        setDatos({...datos,provincia:e.target.value.toUpperCase()})
    }

    const handleChangePais = (e)=>{
        setDatos({...datos,pais:e.target.value.toUpperCase()})
    }

    const handleChangeDenominacion = (e)=>{
        setDatos({...datos,denominacion:e.target.value.toUpperCase()})
    }


    const seleccionarDonante = (id,descripcion)=>{
        nuevoDonante(id,descripcion)
        limpiarDatos()
        finalizarAlta()
    }

    const classes = useStyle();

    return <Box>
        <AlertDialog open={!movimiento.id_donante} // mantengo abierto el diálogo mientras no haya un donante seleccionado, cierro solo si o bien eligió un donante de la lista o si dio de alta un nuevo donante
                    titulo='Cree un donante nuevo' 
                    texto='Por favor complete todos los datos del donante' 
                    procesarCancelar = {procesarCancelar}
                    error={''}>
                <>
                    <Box>
                         <Box sx={{position:esCelular() ? 'relative' : 'sticky',top:0,background:'white',zIndex:1000}}>
                            {mensajeError!=true && <Alert severity="error">{mensajeError}</Alert>}
                        </Box>

                             <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{marginTop:'1rem',display:'flex'}}>
                                            <TextField onInput={(e)=>setTieneFoco(e.target.id)} autoComplete='off' autoFocus={!esCelular()} fullWidth inputProps={{maxLength: 200}} id="dp-nombre" value={datos.nombre} onChange={handleChangeNombre} label="Nombre *" variant="outlined" />
                                            <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-nombre'}/> 
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Box sx={{marginTop:'1rem',display:'flex'}}>
                                            <TextField onInput={(e)=>setTieneFoco(e.target.id)} autoComplete='off' fullWidth inputProps={{maxLength: 200}} id="dp-apellido" value={datos.apellido} onChange={handleChangeApellido} label="Apellido *" variant="outlined" />
                                            <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-apellido'}/> 
                                        </Box>
                                    </Grid>
                                </Grid>
                                {!datos.particular && <Grid item xs={12}>
                                    <Alert sx={{marginBottom:'0.5rem'}} severity="info">{`Cuando el donante es una iglesia el nombre y apellido corresponden al pastor de la misma`}</Alert>
                                </Grid>}
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12} sm={6}>
                                        <FormControlLabel control={<Switch checked={!datos.particular} onChange={handleChangeParticular} />} label={`El donante es una iglesia`} />
                                </Grid>
                            </Grid>
                            {!datos.particular && <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 200}} id="dp-institucion" value={datos.institucion} onChange={handleChangeInstitucion} label="Institución/Iglesia *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-institucion'}/> 
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 200}} id="dp-denominacion" value={datos.denominacion} onChange={handleChangeDenominacion} label="Denominación *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-denominacion'}/> 
                                </Box>
                            </Grid>
                            </Grid>}
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item xs={12} sm={6}>
                                    <Box sx={{marginTop:'1rem',display:'flex'}}>
                                        <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 15}} id="dp-telefono" value={datos.telefono} onChange={handleChangeTelefono} label="Teléfono fijo *" variant="outlined"  helperText="Incluya el código de área. Por ejemplo: 11-1234-5678"/>
                                        <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-telefono'}/> 
                                    </Box>
                                </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 15}} id="dp-celular" value={datos.celular} onChange={handleChangeCelular} label="Teléfono móvil *" variant="outlined"  helperText="Incluya el código de área. Por ejemplo 11-1234-5678"/>
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-celular'}/> 
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 200}} id="dp-email" value={datos.email} onChange={handleChangeEmail} label="E-mail *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-email'}/> 
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 120}} id="dp-direccion" value={datos.direccion} onChange={handleChangeDireccion} label="Dirección *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-direccion'}/> 
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 20}} id="dp-localidad" value={datos.localidad} onChange={handleChangeLocalidad} label="Localidad *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-localidad'}/> 
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 20}} id="dp-cp" value={datos.cod_postal} onChange={handleChangeCodPostal} label="Código postal *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-cp'}/> 
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}  sm={6}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 20}} id="dp-provincia" value={datos.provincia} onChange={handleChangeProvincia} label="Provincia *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-provincia'}/> 
                                </Box>
                            </Grid>
                            <Grid item xs={12}  sm={6}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 20}} id="dp-pais" value={datos.pais} onChange={handleChangePais} label="País *" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-pais'}/> 
                                </Box>
                            </Grid>
                        </Grid>
                     

                        <Grid container spacing={2} alignItems='center'>
                            <Grid item xs={12}>
                                <Box sx={{marginTop:'1rem',display:'flex'}}>
                                    <TextField onInput={(e)=>setTieneFoco(e.target.id)} fullWidth inputProps={{maxLength: 120}} id="dp-observaciones" value={datos.observaciones} onChange={handleChangeObservaciones} label="Observaciones(opcional)" variant="outlined" />
                                    <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-observaciones'}/> 
                                </Box>
                            </Grid>
                        </Grid>
                    {validarDatos()===true && <CrearDonante datos={datos} 
                                                seleccionarDonante={seleccionarDonante}
                                                donantes={donantes}
                                                buscarDonantes = {buscarDonantes}/>}

                        {mensajeError!=true && <Alert severity="error">{mensajeError}</Alert>}
                     </Box>                        
                </>
        </AlertDialog>
    </Box>
}

const CrearDonante = ({datos,seleccionarDonante,donantes,buscarDonantes})=>{
    const [procesando,setProcesando] = useState(false)
    const [confirmar,setConfirmar] = useState(false)
    const detalles = [`Nombre: ${datos.nombre}`,`Apellido: ${datos.apellido}`,`Institución: ${datos.institucion}`,`Ubicación: ${datos.direccion} - ${datos.localidad} (${datos.pais})`]
    const [similitudes,setSimilitudes]=useState([])

    useEffect(()=>{
        const resultado = encontrarSimilitudes(datos,donantes)
        
        setSimilitudes(resultado)

    },[])

    const procesar = (confirmacion) =>{

        if(confirmacion===true){ // para controlar si cerró haciendo click en SI y no afuera del dialog
           

            crearNuevoDonante()
            .catch(err=>{alert(err)
                    })
            setConfirmar(false)
        }else{ 
            setConfirmar(false)
        }
    }

    const crearNuevoDonante = async ()=>{

        try{

            setProcesando(true)
          
            const respuesta = await Axios.post(`/api/tablasgenerales/donante`,{...datos})
        
            const id_nuevo_donante = respuesta.data.id_donante
            if(id_nuevo_donante){
                setTimeout(() => {
                    setProcesando(false)
                    buscarDonantes()
                    seleccionarDonante(id_nuevo_donante,`${datos.apellido}, ${datos.nombre} ${datos.institucion.length>0 ? ` (${datos.institucion}) `:''}`)
                }, 1000);
    
                Swal.fire({
                    html:`Se creó correctamente el nuevo donante`,
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                })
            }else{
                Swal.fire({
                    html:'No se creó el donante, por favor informe a la oficina del DNM',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                })
            }
          

        }catch(err){
            setProcesando(false)
            if(err.response?.data?.message){                
                Swal.fire({
                    html:err.response.data.message,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                })
            }else{
                Swal.fire({
                    html:"Se produjo un error al grabar el donante",
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                })
            }
        }

    }

    return  <>
          {similitudes.length>0 && <Alert variant='filled' severity="warning">
            Hay donantes con el mismo nombre y apellido
            <DetallesSimilitudes similitudes={similitudes}/>
        </Alert>}

          <AlertDialog open={confirmar} procesarResultado={procesar} procesarCancelar={procesar} titulo={`¿Confirma al nuevo donante?`} texto="">
                    {detalles.map((item)=><p style={{padding:'0.25rem'}}>{item}</p>)}
          </AlertDialog>
          <Box sx={{display:'flex',justifyContent:'center',marginTop:'1rem'}}>
                <Button 
                    onClick={()=>setConfirmar(true)} 
                    variant='contained' color='primary' size='large'>
                    Crear el nuevo donante
                </Button>
          </Box>
          
    </>
}

function validarEmail(email){
 
    //http://www.regular-expressions.info/email.html
     const re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
     
     return re.test(String(email).toLowerCase().trim());
}

const encontrarSimilitudes = (datos,donantes)=>{

    const resultado = donantes.filter(item=>datos.apellido.trim()==item.apellido.toUpperCase().trim() && datos.nombre.trim()==item.nombre.toUpperCase().trim())

    return resultado
}

const DetallesSimilitudes = ({similitudes})=>{
    return <Box>
        {similitudes.map(item=><Box>
                <Paper elevation={2} variant="outlined">{item.nombre} {item.apellido} {item.direccion} ID ({item.id_donante})</Paper>
                <Divider sx={{marginTop:'0.5rem'}}/>
        </Box>)}
    </Box>
}