import React,{useState,useEffect} from 'react'
import {egresoContext} from '../egresos/contexto/egresoContext'
import {ingresoContext} from '../ingresos/contexto/ingresoContext'

export const useContextosSegunTipo = (tipo)=>{
    let contexto;

    if(tipo.includes('ingresos')){
        contexto = ingresoContext
    }else{
        contexto = egresoContext
    }

    return {contexto}
}