import React, {createContext} from 'react'
import {useEgresos} from '../hooks/useEgresos'

export const egresoContext = createContext()

const Provider = egresoContext.Provider

export const FormularioContextProvider = ({children})=>{ // Exporto el Provider

    const { egreso,handleChangeTipoSalida,
        handleChangeAnio,
        handleChangeMes,
        handleChangeGira,
        handleChangeDescripcion,
        handleChangeMonto,datosOk,enviarInforme,detalles,handleChangeOtroEgreso,
        resetearDescripcion,
        crearVectorAnios,
        egresoString,
        procesando,finalizado,reinicializar,
        buscarEgresos,
        egresos,
        id_misionero,
        setFiltros,
        filtros,
        periodos,
        formatearMontoBlur,formatearMontoFoco} = useEgresos()

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{egreso,handleChangeTipoSalida,
        handleChangeAnio,
        handleChangeMes,
        handleChangeGira,
        handleChangeDescripcion,
        handleChangeMonto,datosOk,enviarInforme,detalles,handleChangeOtroEgreso,
        resetearDescripcion,
        crearVectorAnios,
        egresoString,
        procesando,finalizado,reinicializar,
        buscarEgresos,egresos,id_misionero,setFiltros,filtros,periodos,
        formatearMontoBlur,formatearMontoFoco}}> 
        <div>
            {children}
        </div>
    </Provider>
}
