import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {egresoContext} from '../contexto/egresoContext'
import Autocomplete from '@mui/material/Autocomplete';


/*
Ejemplo de autocomplete.
El array de opciones espera tener un id y un label

 <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={[{id:1,label:'pepe'},{id:2,label:'chocho'}]}
      onChange={(event, value) =>handleChangeTipoSalida(event)}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Movie" />}
    />
*/

export const FiltrosEgresos = ()=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const [tipoSeleccionado,setTipoSeleccionado] = useState(null)
    const [clase,setClase] = useState(null)
    const {setFiltros,filtros,egresos,periodos} = useContext(egresoContext)
    
    useEffect(()=>{
        const buscarTipos = async ()=>{
            try{
                const {data} = await Axios.get('/api/tablasgenerales/tipos_salida')
                setTiposSalida(data)
            }catch(err){
                console.log(err)
                alert('Se produjo un error al buscar los tipos de egresos')
            }
        }

        buscarTipos()
    },[])

    useEffect(()=>{
        if(tipoSeleccionado){
            setFiltros({...filtros,id_tipo_salida:tipoSeleccionado})
        }else{
            const copia = {...filtros}
            delete copia.id_tipo_salida
            setFiltros({...copia})
        }
    },[tipoSeleccionado])

    useEffect(()=>{
        if(clase){
            setFiltros({...filtros,clase:clase})
        }else{
            const copia = {...filtros}
            delete copia.clase
            setFiltros({...copia})
        }
    },[clase])

    const handleChangeTipoSalida = (value)=>{
        setTipoSeleccionado(value ? Number(value.id) : null)
    }

    const handleChangeClase = (value)=>{
        setClase(value ? value : null)
    }

    const handleChangePeriodo = (value)=>{
        
        if(value){
            setFiltros({...filtros,mesString:value.id.mes,anio:value.id.anio})
        }else{
            const copia = {...filtros}
            delete copia.mesString
            delete copia.anio
            setFiltros({...copia})
        }
        
    }

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-tiposalida"
                    //value={egreso.id_tipo_salida>0 ? tiposSalida.filter(item=>item.id_tipo_salida==egreso.id_tipo_salida)[0].nombre : ''}
                    options={tiposSalida.map(item=>{return {id:item.id_tipo_salida,label:item.nombre}})}
                    onChange={(event, value) => handleChangeTipoSalida(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por egreso" />}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-tiposalida"
                    //value={egreso.id_tipo_salida>0 ? tiposSalida.filter(item=>item.id_tipo_salida==egreso.id_tipo_salida)[0].nombre : ''}
                    options={['Gira','Campo']}
                    onChange={(event, value) => handleChangeClase(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por clase" />}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Autocomplete
                    disablePortal
                    id="ac-tiposalida"
                    options={periodos}
                    onChange={(event, value) => handleChangePeriodo(value)}
                    sx={{ minWidth: 200 }}
                    renderInput={(params) => <TextField {...params} label="Filtre por período" />}
                    />
                </Grid>
            </Grid>
        </Box>
            
}

const cargarPeriodos = (setPeriodos,data)=>{
    const periodos = new Map()

    data.forEach(item=>{
        const periodo = `${item.mesString} ${item.anio}`
        const objetoPeriodo = {mes:item.mesString,anio:item.anio}
        console.log(periodo,objetoPeriodo)
        periodos.set(periodo,objetoPeriodo)
    })

    setPeriodos(Array.from(periodos.entries()))
}