import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,InputAdornment,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import CheckIcon from '@mui/icons-material/CheckCircle';
import {CheckInputCelular} from './CheckInputCelular'

export const InputMonto = ({contexto})=>{
    const {movimiento,handleChangeMonto,formatearMontoBlur,formatearMontoFoco,montoFormateado,esCelular} = useContext(contexto)
    const [tieneFoco,setTieneFoco]=useState('')

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <Box sx={{marginTop:'1rem',display:'flex'}}>
                    <TextField fullWidth 
                        inputProps={{maxLength: 25}} 
                        id="dp-monto" 
                        value={movimiento.monto} 
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        onBlur={formatearMontoBlur}
                        onFocus={formatearMontoFoco}
                        onChange={handleChangeMonto}
                        onKeyUp={()=>console.log(montoFormateado)}
                        onInput={(e)=>setTieneFoco(e.target.id)}
                       label={`Monto: ${montoFormateado}`} variant="outlined" />
                       <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='dp-monto'}/> 
                </Box>
            </Box>
}