
import React, {useContext, useEffect, useRef,useState} from 'react'
import {Card,Box,
        CardActions,LinearProgress,Typography,
        CardContent,CardHeader } from "@material-ui/core";
import {hacerScroll} from '../../Helpers/utilidades-globales'
import Alert from '@mui/material/Alert';

export const GenericCard = ({titulo,
                            children,
                            id,
                            mostrar,
                            error,
                            mensajeError,
                            dobleMensajeError,
                            noHacerScroll,
                            subtitulo,
                            mostrarLoading,estilo})=>{
    const [activarLoading,setActivarLoading] =useState(false)

    useEffect(()=>{
        if(mostrar && !noHacerScroll){
            mostrarLoading && setActivarLoading(true)
            setTimeout(() => {
                hacerScroll(id)
            }, 500);
            setTimeout(() => {
                setActivarLoading(false)
            }, 1500);
        }
    }
    ,[mostrar])

if (!mostrar){
    return null
}

return <Box sx={estilo}>
    <Card variant='outlined' style={{background:'#F5F5F5',minWidth:'280px',borderRadius:'30px',marginBottom:'0.5rem'}}>
        {titulo && <CardHeader id={id} title={titulo}
                    titleTypographyProps={{
                        variant: "h6",
                        align: "left"
                        ,color:'primary'
                        }}
        /> }
        {activarLoading && <Box sx={{ width: '100%' }}>
            <Typography variant='body2'>{titulo}</Typography>
            <LinearProgress title='Cargando'/>
        </Box>}
        {subtitulo && <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>{subtitulo}</Typography>}
        {error &&  dobleMensajeError && <Alert severity="error">{mensajeError}</Alert>}
        <CardContent>
            {children}
        </CardContent>
        <CardActions>
        </CardActions>
    </Card>
    {error && <Alert severity="error">{mensajeError}</Alert>}
</Box>
}