import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {egresoContext} from '../contexto/egresoContext'

export const RadioGiraCampo = ()=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const {egreso,handleChangeGira} = useContext(egresoContext)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return  <Box sx={{marginTop:'1rem',display:'flex', justifyContent:'center'}}><FormControl>
        <RadioGroup
            name="tipo-egreso"
            value={egreso.gira ? 'G' : 'C'}
            onChange={handleChangeGira}>
                <FormControlLabel value="G" control={<Radio />} label="En Gira" />
                <FormControlLabel value="C" control={<Radio />} label="En Campo" />
        </RadioGroup>
</FormControl>
</Box>
}