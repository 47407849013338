
import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Main from './Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faTimes } from '@fortawesome/free-solid-svg-icons';
import Seleccionador from './Seleccionador'
import usePaginacion from '../hooks/usePaginacion';
import Paginacion from '../componentes/Paginacion';
import {v4 as uuidv4} from 'uuid'

export default function Cuenta({usuario}){

const [datos,setDatos] = useState([])
const [periodos,setPeriodos] = useState([])
const [cajas,setCajas] = useState([])
const [listado,setListado] = useState([])
const [cajaSeleccionada,setCajaSeleccionada] = useState(null)
const [periodoSeleccionado,setPeriodoSeleccionado] = useState(null)
const [status,setStatus] = useState(null)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [verQr,setVerQr] = useState(false)
const anchoPaginacion = 50;
const {configuracion,paginar,inicializar} = usePaginacion();

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/fondos/cajas/${usuario.id_misionero}`)]) 
            setBuscandoDatos(false)
            return vectorResultado[0].data

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()
    .then(cajas=>{
        if(cajas.length>0){
            setCajaSeleccionada(cajas[0].id_caja)
            setCajas(cajas)
        }else{
            setCajas([])
            setStatus("Sin cajas")
        }
       
    })               
},[])

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/fondos/caja/periodos/${usuario.id_misionero}/${cajaSeleccionada}`)]) 
            setBuscandoDatos(false)
            return vectorResultado[0].data.sort((a,b)=>Number(b.clave) - Number(a.clave))

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    if(cajaSeleccionada){
        obtenerDatos()
        .then(periodos=>{
            setPeriodos(periodos)
            // El período seleccionado debe inicializarse siempre tomando el primer período del listado, porque cada caja puede tener diferentes períodos
            setPeriodoSeleccionado(periodos[0].clave)
        }) 
    }
                 
},[cajaSeleccionada])

useEffect(()=>{
    inicializar(anchoPaginacion,listado)
},[listado])

useEffect(()=>{
    const obtenerDatos = async ()=>{

        const year = periodos.filter(item=>item.clave==periodoSeleccionado).map(item=>item.año)[0]
        const month = periodos.filter(item=>item.clave==periodoSeleccionado).map(item=>item.mes)[0]

        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/fondos/movimientos/${usuario.id_misionero}/${cajaSeleccionada}/${year}/${month}`)]) 
            setBuscandoDatos(false)
            return vectorResultado[0].data

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }

    if (periodoSeleccionado && cajaSeleccionada && periodos.length>0 ){

        console.log('amigo',periodos,periodoSeleccionado,cajaSeleccionada)
        obtenerDatos()
        .then(listado=>{
            setListado(listado)
        }) 

    }
      
},[periodoSeleccionado,cajaSeleccionada])

const handleCajaChange = (e)=>{
    setCajaSeleccionada(e.target.value)
}
    if (buscandoDatos){
        return <Main center alinear><div className="cont-loading"><Loading/><span className="cargando">Buscando datos...</span></div></Main>
    };   
    
   

    if(status=='Sin cajas'){
        return <Main center><p className="text-center">No se encontraron cajas asociadas con su cuenta</p></Main>
    }

    if(!periodoSeleccionado){
        return <p>No se ha seleccionado un período</p>
    }

    if(!listado){
        return <p>No se ha seleccionado un período</p>
    }

return <Main center>

    <div className="flex justify-center items-center f-col">
        {/*<Seleccionador id="id_caja" noDefault vector={cajas} valor={cajaSeleccionada} onchange={(e)=>setCajaSeleccionada(e.target.value)} claves={{id:'id_caja',nombre:'nombre'}}/>*/}
        
        {renderCajas(cajas,cajaSeleccionada,handleCajaChange,setCajaSeleccionada)}
        
        <div className="mt-4">
            <Seleccionador etiqueta='Período' id="clave" noDefault vector={periodos} valor={periodoSeleccionado} onchange={(e)=>setPeriodoSeleccionado(e.target.value)} claves={{id:'clave',nombre:'nombre'}}/>
        </div>
    </div>
    <Paginacion configuracion={configuracion} paginar={paginar}/>

    <table className="table2 mt-8 table-cent">
        <thead>
            <tr>
                <th className="text-left a-col1" >Fecha</th>
                <th className="text-left a-col2">Recibo</th>
                <th className="text-right a-col3">Acumulado</th>
                <th className="text-right a-col4">Importe</th>
                <th className="text-right a-col5">Saldo</th>
            </tr>
        </thead>
        {
            listado
            .map((item,index)=>{return {...item,indice:index+1}})
            .filter((item,index)=>{
                return index>= configuracion.iIni && index<=configuracion.iFin
            })
            .map(curso => {
                return (
                    <tbody>
                       <tr title={curso.id_recibo ? `Recibo ${curso.id_recibo}`:''} key={uuidv4()} className={`border-bottom-solid ${curso.ingreso==0 ? 'bg-egreso' : ''}`}>
                            <td className="w-3r a-col1">{curso.fecha}</td>
                            <td className="w-3r a-col2">{curso.comprobante}</td>
                            <td className="text-right w-3r a-col3">{curso.saldo_acum ? `${curso.saldo_acum}` : ''}</td>
                            <td className="text-right w-3r a-col4">{curso.ingreso!="0,00" ? `${curso.ingreso}` : curso.egreso!="0,00" ? `-${curso.egreso}` : '0.00'}</td>
                            <td className="text-right w-3r a-col5">{curso.saldo ? `${curso.saldo}` : ''}</td>
                        </tr>
                    
                    </tbody>
                    
                )
            })
        }
    </table>

</Main>
return <Main center>
    <div className="flex justify-center items-center f-col">
        {/*<Seleccionador id="id_caja" noDefault vector={cajas} valor={cajaSeleccionada} onchange={(e)=>setCajaSeleccionada(e.target.value)} claves={{id:'id_caja',nombre:'nombre'}}/>*/}
        
        {renderCajas(cajas,cajaSeleccionada,handleCajaChange)}
        
        <div className="mt-4">
            <Seleccionador id="clave" noDefault vector={periodos} valor={periodoSeleccionado} onchange={(e)=>setPeriodoSeleccionado(e.target.value)} claves={{id:'clave',nombre:'nombre'}}/>
        </div>
    </div>
     <div>
         {JSON.stringify(listado)}
     </div>
</Main>
}


function renderCajas(cajas,cajaSeleccionada,handleCajaChange,setCajaSeleccionada){
    return <table>
    {cajas.map(item=><tr className="cursor-pointer" onClick={()=>setCajaSeleccionada(item.id_caja)}><td className="nivei-nivele text-large"><span className={cajaSeleccionada==item.id_caja ? "border-bottom-red" : ""}>{item.nombre}</span></td>
    <td><input type="radio" id="caja"  className="text-large"  value={item.id_caja} checked={cajaSeleccionada==item.id_caja} onChange={handleCajaChange}/>
    </td>
    </tr> 
    )}         
    </table>
   
 }
