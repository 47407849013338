/*import React,{useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const AlertDialog = ({abrir,handleClose,title,body,children})=> {

  return (
    <div>
      <Dialog
        open={abrir}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent sx = {{minWidth:'280px'}}
>
          <DialogContentText id="alert-dialog-description">
           {body}
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(false)}>No</Button>
          <Button onClick={()=>handleClose(true)} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

*/

import React, {useContext} from 'react'
import {makeStyles,Button,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import {useAlumno} from '../../Context/alumnoContext'
export const AlertDialog = ({open,procesarResultado,titulo,children,texto,procesarCancelar,error})=>{  
    
  const {esCelular} = useAlumno()

  const useStyle = makeStyles({
    scrollPaper: {
    
    }
  });

  
  const classes = useStyle();

    
    return <div>
    <Dialog  classes={{scrollPaper: classes.scrollPaper}} fullScreen={esCelular()} open={open} onClose={procesarResultado}>
      <IconButton aria-label="Cancelar" onClick={procesarCancelar} component="label">
            <CloseIcon sx={{color:'red'}} />
      </IconButton>
      <DialogTitle>{titulo}</DialogTitle>
      <DialogContent>
        {texto && <DialogContentText>
            {texto}
        </DialogContentText>}
        {children}
        {error && <p style={{color:'red',margin:'8px'}}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>procesarCancelar(false)}>Cancelar</Button>
        {procesarResultado && <Button onClick={()=>procesarResultado(true)}>Aceptar</Button>}
      </DialogActions>
    </Dialog>
  </div>
}