import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {ingresoContext} from '../contexto/ingresoContext'
import { AlertDialog } from '../../../componentes/MUI/AlertDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {useAlumno} from '../../../Context/alumnoContext'

export const SelectTiposIngreso = ()=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const {egreso,handleChangeTipoSalida,handleChangeDescripcion,resetearDescripcion} = useContext(ingresoContext)
    const [pedirDescricion,setPedirDescripcion] = useState(false)
    const {esCelular} = useAlumno()

    useEffect(()=>{
        const buscarTipos = async ()=>{
            try{
                const {data} = await Axios.get('/api/tablasgenerales/tipos_salida')
                setTiposSalida(data)
            }catch(err){
                console.log(err)
                alert('Se produjo un error al buscar los tipos de egresos')
            }
        }

        buscarTipos()
    },[])

    useEffect(()=>{
        if(egreso.id_tipo_salida==19){
            setPedirDescripcion(true)
        }else{
            setPedirDescripcion(false)
        }
    },[egreso.id_tipo_salida])

    const procesarCancelarEgreso = ()=>{
        resetearDescripcion()
        setPedirDescripcion(false)
    }

    const procesarAceptarEgreso = ()=>{
        if(egreso.id_tipo_salida==19 && egreso.descripcion.trim()!=''){
            setPedirDescripcion(false)
        }
    }

    const useStyle = makeStyles({
        select: {
            background: 'white',
            padding:'0.5rem',
            color:'black',

            "& .MuiSelect-selectMenu": {
                whiteSpace:'normal',background:'white'
            }
        },
        selectMenu:{
            whiteSpace:'normal'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <FormControl>
                        {/*<FormLabel id="demo-radio-buttons-group-label">Seleccioná un instrumento</FormLabel>
                        */}
                        <Select
                            labelId="lb-tipo-salida"
                            id="sl-tipo-salida"
                            value={egreso.id_tipo_salida}
                            label="Tipo de egreso"
                            onChange={(e)=>handleChangeTipoSalida(e,nombreSalida(tiposSalida,e.target.value))}
                            className={classes.select}
                        >
                            <MenuItem value={-1} disabled>Seleccione un egreso</MenuItem>
                            {tiposSalida.map(item=>{
                                return <MenuItem className={classes.selectMenu} value={item.id_tipo_salida}>{item.nombre}</MenuItem>
                            })}
                        </Select>
                </FormControl>
                {egreso.descripcion && <Box sx={{ display: 'flex',alignItems: 'center',justifyContent:'left' }}>
                <p style={{textAlign:'left',margin:'0'}}>{egreso.descripcion}</p>    
                <Button title='Borrar egreso' onClick={resetearDescripcion}><DeleteOutlineIcon/></Button>                          
                </Box>} 
                <AlertDialog open={pedirDescricion} 
                    titulo='Especifique un nuevo tipo de egreso' 
                    subtitulo='Egreso nuevo' 
                    procesarCancelar = {procesarCancelarEgreso}
                    procesarResultado={procesarAceptarEgreso}
                    error={''}>
                            <TextField fullWidth id="ub-varios" 
                            inputProps={{maxLength: 100}} 
                            autoFocus={!esCelular()}
                            value={egreso.descripcion} 
                            onChange={handleChangeDescripcion} 
                            label="Nuevo egreso" 
                            variant="outlined" />
                </AlertDialog>
            </Box>
}

const nombreSalida = (salidas,id)=>{
    return salidas.filter(item=>item.id_tipo_salida==id).map(item=>item.nombre)[0]
}