
import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Main from './Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQrcode, faTimes, faArrowAltCircleUp, faArrowAltCircleDown, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import Seleccionador from './Seleccionador'
import usePaginacion from '../hooks/usePaginacion';
import Paginacion from '../componentes/Paginacion';
import Modal from '../componentes/Modal';
import useModal from '../hooks/useModal';

export default function Cuenta({usuario}){

const [datos,setDatos] = useState([])
const [periodos,setPeriodos] = useState([])
const [listado,setListado] = useState([])
const [periodoSeleccionado,setPeriodoSeleccionado] = useState(null)
const [recibo,setRecibo] = useState(null)
const [status,setStatus] = useState(null)
const [buscandoDatos,setBuscandoDatos] = useState(false)
const [verQr,setVerQr] = useState(false)
const anchoPaginacion = 50;
const {configuracion,paginar,inicializar} = usePaginacion();
const [reciboSeleccionado,setReciboSeleccionado]= useState(null)    
const {toggle, isShowing } = useModal();

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/periodos/${usuario.id_misionero}`)]) 
            setBuscandoDatos(false)
            return vectorResultado[0].data

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    obtenerDatos()
    .then(periodos=>{
        if(periodos.length>0){
            setPeriodoSeleccionado(periodos.sort((a,b)=>Number(b.clave)-Number(a.clave))[0].clave)
            setPeriodos(periodos)
        }
    })               
},[])

useEffect(()=>{
    const obtenerDatos = async ()=>{
        setBuscandoDatos(true)
        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/cuenta/${usuario.id_misionero}/${periodoSeleccionado}`)]) 
            setBuscandoDatos(false)
            return vectorResultado[0].data

        }catch(err){
            console.log(err)
            setBuscandoDatos(false)
        }
    }
    if(periodoSeleccionado){
        obtenerDatos()
        .then(listado=>{
            setListado(listado)
        }) 
    }
                 
},[periodoSeleccionado])

useEffect(()=>{
    inicializar(anchoPaginacion,listado)
},[listado])

useEffect(()=>{
    if(reciboSeleccionado){
        buscarRecibo()
    }
},[reciboSeleccionado])


const buscarRecibo = async ()=>{

    //setBuscandoRecibo(true)

    const url = `/api/tablasgenerales/recibo/${usuario.id_misionero}/${reciboSeleccionado.recibo}/${reciboSeleccionado.ingreso}`
    try{
        const { data } = await Axios.get(url)
        setRecibo(data)
      //  setBuscandoRecibo(false)
        
    }catch(err){
        console.log('Error al buscar los datos de un recibo')
       // setBuscandoRecibo(false)
       // setHuboError(true)
        return null
    }

}

    if (buscandoDatos){
        return <Main center alinear><div className="cont-loading"><Loading center/><span className="cargando">Buscando datos...</span></div></Main>
    };    

return <Main center>
    
    { isShowing && recibo && <Modal hide={toggle} 
                                             isShowing={isShowing} 
                                             titulo={"Recibo"}
                                             estiloWrapper={{background:'#000000bf'}}
                                             closeOnclickOutside={true}>
                <Recibo recibo={recibo} cerrar={()=>setReciboSeleccionado(null)}/>                                                 
       </Modal>}  
     <div className="flex justify-center items-center f-col">
         <Seleccionador etiqueta='Período' id="clave" noDefault vector={periodos} valor={periodoSeleccionado} onchange={(e)=>setPeriodoSeleccionado(e.target.value)} claves={{id:'clave',nombre:'nombre'}}/>
     </div>  

     <Paginacion configuracion={configuracion} paginar={paginar}/>

     <table className="table2 mt-8 table-cent">
        <thead>
            <tr>
                <th className="text-left a-col1" >Fecha</th>
                <th className="text-left a-col2">Recibo</th>
                <th className="text-right a-col3">Acumulado</th>
                <th className="text-right a-col4">Importe</th>
                <th className="text-right a-col5">Saldo</th>
            </tr>
        </thead>
        {
            listado
            .map((item,index)=>{return {...item,indice:index+1}})
            .filter((item,index)=>{
                return index>= configuracion.iIni && index<=configuracion.iFin
            })
            .map(curso => {
                return (
                    <tbody>
                       <tr onClick={()=>{setReciboSeleccionado({recibo:curso.id_recibo,ingreso:curso.ingreso});toggle()}} title={`Recibo ${curso.id_recibo}`} key={curso.id_recibo} className={`border-bottom-solid cursor-pointer ${curso.ingreso==0 ? 'bg-egreso' : ''}`}>
                            <td className="w-3r a-col1">{curso.fecha}</td>
                            <td className="w-3r a-col2">{curso.comprobante}</td>
                            <td className="text-right w-3r a-col3">{curso.saldo_acum ? `${curso.saldo_acum}` : ''}</td>
                            <td className="text-right w-3r a-col4">{curso.ingreso== 1 ? curso.disponible ? `${curso.disponible}` : '0.00': curso.salida ? `-${curso.salida}` : '0.00'}</td>
                            <td className="text-right w-3r a-col5">{curso.saldo_total ? `${curso.saldo_total}` : ''}</td>
                        </tr>
                    
                    </tbody>
                    
                )
            })
        }
    </table>

</Main>    

return <Main center>
     <div className="flex justify-center items-center f-col">
         <Seleccionador id="clave" noDefault vector={periodos} valor={periodoSeleccionado} onchange={(e)=>setPeriodoSeleccionado(e.target.value)} claves={{id:'clave',nombre:'nombre'}}/>
     </div>   
     <div>
         {JSON.stringify(listado)}
     </div>
</Main>



}



function Recibo({recibo}){

    console.log(recibo[0])
    return <div className="FormAbmContainer flex f-col ml-2">

            {recibo.length > 0 && <div>

                    <div className="border-bottom-dotted-gray flex f-row justify-content-space-around">
                        <span title="Fecha del recibo" className="">{recibo[0].fecha}
                        </span>
                        <span title="Comprobante" className="">{recibo[0].comprobante}
                        </span>
                        <span title="Tipo de movimiento" className="color-gray">{recibo[0].ingreso!="0,00" ? ingreso() : egreso()}
                        </span>
                    </div>
                   

                    {recibo[0].descripcion && 
                        <div> <span className="color-gray text-small">Descripcion</span>
                            <p title="Descripción" className="border-bottom-dotted-gray cursor-pointer">{recibo[0].descripcion}</p>
                        </div>}
                    {recibo[0].detalle && 
                        <div> <span className="color-gray text-small">Descripcion</span>
                            <p title="Descripción" className="border-bottom-dotted-gray cursor-pointer">{recibo[0].detalle}</p>
                        </div>}

                    {recibo[0].donante && <div className="mt-4"> <span className="color-gray text-small">Donante</span>
                        <p title="Donante" className="border-bottom-dotted-gray cursor-pointer">{recibo[0].donante}
                        </p>
                        {/*<p title="E-mail" className="">{recibo[0].email != "" ? recibo[0].email :''}
                        </p>*/}
                        {recibo[0].email != "" && recibo[0].email != null && <a target="_blank" href={crearMailToIndividual(recibo[0].email)} >{recibo[0].email}</a>} 
                    </div>}
     
              </div>  }

              {recibo.length > 0 && <div className="flex f-col text-large mt-4">

                <table className="table2">
                    <thead>
                            {recibo[0].ingreso && <tr>
                                <td className="filas-lista-nw ti-monto color-gray rec-cond-ls" >Monto</td>
                                <td className="filas-lista-nw ti-monto color-gray rec-cond-ls">Disponible</td>
                                <td className="filas-lista-nw ti-monto color-gray rec-cond-ls">Retención</td>
                            </tr>}
                    </thead>    
                       
                    <tbody>
                           
                    { recibo.map(item=>{
                            return <tr>
                            <td className="filas-lista-nw ti-monto rec-cond-ls0">
                                <div className="flex f-col items-flex-start">
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Monto</span> {item.ingreso!="0,00" ? item.ingreso : `-${item.egreso}`}</p>
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Disponible</span> {item.disponible}</p>
                                        <p className="p-2"><span className="color-gray inline-block-1 w-100 text-left">Retenido</span> {item.distribuido}</p>
                                </div>
                            </td>
                            <td className="filas-lista-nw ti-monto rec-cond-ls">{item.ingreso!="0,00" ? item.ingreso : `-${item.egreso}`}</td>
                            <td className="filas-lista-nw ti-monto rec-cond-ls">{item.disponible}</td>
                            <td className="filas-lista-nw ti-monto rec-cond-ls">{item.distribuido}</td>
                        </tr>})
                    }
                  </tbody>
                  </table>
              </div>}
              {recibo.length == 0 && !recibo && <div className="flex f-col text-large">
                  <div className="ig-min"><span>{`No tiene iglesias autónomas a cargo`}</span></div>
          </div>}                 
      </div>

}

function crearMailToIndividual(email){
    return email!=null && email!='' ? `mailto: ${email}` : ``
}

function ingreso(){
    return <div className="flex color-green">
                    <FontAwesomeIcon icon={faArrowAltCircleDown}/>
                    <p className="ml-2">Ingreso</p>
    </div>
}

function egreso(){
    return <div className="flex color-red">
         <FontAwesomeIcon icon={faArrowAltCircleUp}/>
         <p className="ml-2">Egreso</p>
    </div>
}