import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import { AlertDialog } from '../../componentes/MUI/AlertDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {CheckInputCelular} from './CheckInputCelular'

export const SelectEstadoIngreso = ({contexto})=>{
    const {movimiento,handleChangeEstado,descripcion,estados} = useContext(contexto)

    //const estados = [{id:1,nombre:'pepe'},{id:2,nombre:'pipi'},{id:3,nombre:'tata'}]
    const useStyle = makeStyles({
        select: {
            background: 'white',
            padding:'0.1rem',
            color:'black',

            "& .MuiSelect-selectMenu": {
                whiteSpace:'normal',background:'white'
            }
        },
        selectMenu:{
            whiteSpace:'normal'
        }
      });

    const classes = useStyle();

    if (descripcion=='Egreso'){
        return null
    }

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'0.5rem'}}> 
                <FormControl style={{width:'100%'}}>
                        {movimiento.id_estado_ingreso !=-1 && <FormLabel id="lb-estado-ingreso">Estado</FormLabel>
                        }
                        <Select
                            labelId="lb-estado-ingreso"
                            fullWidth
                            id="sl-estadp-ingreso"
                            value={movimiento.id_estado_ingreso}
                            label="Estado del ingreso"
                            onChange={handleChangeEstado}
                            className={classes.select}
                        >
                            <MenuItem value={-1} disabled>Seleccione un estado</MenuItem>
                            {estados.map(item=>{
                                return <MenuItem className={classes.selectMenu} value={item.id}>{item.nombre}</MenuItem>
                            })}
                        </Select>
                </FormControl>
            </Box>
}

