import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import { AlertDialog } from '../../componentes/MUI/AlertDialog';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {CheckInputCelular} from './CheckInputCelular'

export const SelectTiposMovimiento = ({contexto})=>{
    const {movimiento,handleChangeTipoSalida,handleChangeDescripcion,resetearDescripcion,conceptos,esCelular} = useContext(contexto)
    const [pedirDescricion,setPedirDescripcion] = useState(false)
    const [tieneFoco,setTieneFoco] = useState('')

    useEffect(()=>{
        if(movimiento.id==19){
            setPedirDescripcion(true)
        }else{
            setPedirDescripcion(false)
        }
    },[movimiento.id])

    const procesarCancelarEgreso = ()=>{
        resetearDescripcion()
        setPedirDescripcion(false)
    }

    const procesarAceptarEgreso = ()=>{
        if(movimiento.id==19 && movimiento.descripcion.trim()!=''){
            setPedirDescripcion(false)
        }
    }

    const useStyle = makeStyles({
        select: {
            background: 'white',
            padding:'0.5rem',
            color:'black',

            "& .MuiSelect-selectMenu": {
                whiteSpace:'normal',background:'white'
            }
        },
        selectMenu:{
            whiteSpace:'normal'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <FormControl>
                        {/*<FormLabel id="demo-radio-buttons-group-label">Seleccioná un instrumento</FormLabel>
                        */}
                        <Select
                            labelId="lb-tipo-salida"
                            id="sl-tipo-salida"
                            value={movimiento.id}
                            label="Tipo de egreso"
                            onChange={(e)=>handleChangeTipoSalida(e,nombreSalida(conceptos,e.target.value))}
                            className={classes.select}
                        >
                            <MenuItem value={-1} disabled>Seleccione un concepto</MenuItem>
                            {conceptos.map(item=>{
                                return <MenuItem className={classes.selectMenu} value={item.id}>{item.nombre}</MenuItem>
                            })}
                        </Select>
                </FormControl>
                {movimiento.descripcion && <Box sx={{ display: 'flex',alignItems: 'center',justifyContent:'left' }}>
                <p style={{textAlign:'left',margin:'0'}}>{movimiento.descripcion}</p>    
                <Button title='Borrar egreso' onClick={resetearDescripcion}><DeleteOutlineIcon/></Button>                          
                </Box>} 
                <AlertDialog open={pedirDescricion} 
                    titulo='Especifique un nuevo concepto' 
                    subtitulo='Concepto nuevo' 
                    procesarCancelar = {procesarCancelarEgreso}
                    procesarResultado={procesarAceptarEgreso}
                    error={''}>
                            <Box>
                                <TextField fullWidth id="ub-varios" 
                                inputProps={{maxLength: 100}} 
                                onInput={(e)=>setTieneFoco(e.target.id)}
                                autoFocus={!esCelular()}
                                value={movimiento.descripcion} 
                                onChange={handleChangeDescripcion} 
                                label="Nuevo egreso" 
                                variant="outlined" />

                                <CheckInputCelular contexto={contexto} setTieneFoco={setTieneFoco} mostrar={tieneFoco=='ub-varios'}/> 
                            </Box>
                </AlertDialog>
            </Box>
}

const nombreSalida = (salidas,id)=>{
    return salidas.filter(item=>item.id==id).map(item=>item.nombre)[0]
}