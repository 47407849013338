import React, {createContext} from 'react'
import {useIngresos} from '../hooks/useIngresos'

export const ingresoContext = createContext()

const Provider = ingresoContext.Provider

export const FormularioContextProvider = ({children})=>{ // Exporto el Provider

    const { ingreso,handleChangeTipoSalida,
        handleChangeAnio,
        handleChangeMes,
        handleChangeGira,
        handleChangeDescripcion,
        handleChangeMonto,datosOk,enviarInforme,detalles,handleChangeOtroEgreso,
        resetearDescripcion,
        crearVectorAnios,
        ingresoString,
        procesando,finalizado,reinicializar,
        buscarEgresos,
        ingresos,
        id_misionero,
        setFiltros,
        filtros,
        periodos,
        formatearMontoBlur,formatearMontoFoco,
        descripcion,
        conceptos,donantes,handleChangeDonante,donanteString,
        solicitarDonante,buscarDonantes,nuevoDonante,borrarDonante,montoFormateado,
        esCelular,handleChangeObservaciones,handleChangeEstado,
        estados} = useIngresos()

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{handleChangeTipoSalida,
        handleChangeAnio,
        handleChangeMes,
        handleChangeGira,
        handleChangeDescripcion,
        handleChangeMonto,datosOk,enviarInforme,detalles,handleChangeOtroEgreso,
        resetearDescripcion,
        crearVectorAnios,
        procesando,finalizado,reinicializar,
        buscarEgresos,id_misionero,setFiltros,filtros,periodos,
        formatearMontoBlur,formatearMontoFoco,
        movimiento:ingreso,
        movimientos:ingresos,
        movimientoString:ingresoString,
        descripcion,
        conceptos,donantes,handleChangeDonante,donanteString,solicitarDonante,
        buscarDonantes,nuevoDonante,borrarDonante,montoFormateado,
        esCelular,handleChangeObservaciones,handleChangeEstado,
        estados
    }}> 
        <div>
            {children}
        </div>
    </Provider>
}
