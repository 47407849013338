import React, {createContext} from 'react'
import {useEgresos} from '../hooks/useEgresos'

export const egresoContext = createContext()

const Provider = egresoContext.Provider

export const FormularioContextProvider = ({children})=>{ // Exporto el Provider

    const { handleChangeTipoSalida,
        handleChangeAnio,
        handleChangeMes,
        handleChangeGira,
        handleChangeDescripcion,
        handleChangeMonto,datosOk,enviarInforme,detalles,handleChangeOtroEgreso,
        resetearDescripcion,
        crearVectorAnios,
        procesando,finalizado,reinicializar,
        buscarEgresos,
        id_misionero,
        setFiltros,
        filtros,
        periodos,
        formatearMontoBlur,formatearMontoFoco,
        egreso,
        egresos,
        egresoString,
        descripcion,
        conceptos,montoFormateado,esCelular,handleChangeObservaciones,handleChangeRetirado} = useEgresos()

    // En el value de Provider va todo lo que deseo compartir con los children
    return <Provider value={{handleChangeTipoSalida,
        handleChangeAnio,
        handleChangeMes,
        handleChangeGira,
        handleChangeDescripcion,
        handleChangeMonto,datosOk,enviarInforme,detalles,handleChangeOtroEgreso,
        resetearDescripcion,
        crearVectorAnios,
        procesando,finalizado,reinicializar,
        buscarEgresos,id_misionero,setFiltros,filtros,periodos,
        formatearMontoBlur,formatearMontoFoco,
        movimiento:egreso,
        movimientos:egresos,
        movimientoString:egresoString,
        descripcion,conceptos,montoFormateado,esCelular,handleChangeObservaciones,handleChangeRetirado}}> 
        <div>
            {children}
        </div>
    </Provider>
}
