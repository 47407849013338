import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";

export const CheckRetirado = ({contexto})=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const {movimiento,handleChangeRetirado,descripcion} = useContext(contexto)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    if (descripcion=='Ingreso'){
        return null
    }

    return  <Box sx={{marginTop:'1rem',display:'flex', justifyContent:'center'}}><FormControl>
            <FormControlLabel control={<Switch checked={movimiento.retirado} onChange={handleChangeRetirado} />} label={`Retirado`} />
</FormControl>
</Box>
}