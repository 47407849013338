import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography} from "@material-ui/core";
import CheckCircleIcon  from '@mui/icons-material/CheckCircle';

export const CheckInputCelular = ({contexto,mostrar,setTieneFoco})=>{
    const {esCelular} = useContext(contexto)

    return <Box> 
               {esCelular() &&  mostrar && <Button onFocus={(e)=>setTieneFoco(e.target.id)}><Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}><CheckCircleIcon fontSize='large' color="primary"/><Typography variant='caption'>ENTER</Typography></Box></Button>}
        </Box>
}