import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import SendIcon  from '@mui/icons-material/Send';
import {AlertDialog} from '../../componentes/MUI/AlertDialog'
import Swal from 'sweetalert2';

export const ButtonEnviar = ({contexto})=>{
    const {movimiento,enviarInforme,detalles,descripcion} = useContext(contexto)
    const [confirmar,setConfirmar] = useState(false)

    const procesar = (confirmacion) =>{

        if(confirmacion===true){ // para controlar si cerró haciendo click en SI y no afuera del dialog
            enviarInforme()
            .then(mensaje=>{
                Swal.fire({
                    html:mensaje,
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                }).then(()=>{
                    setConfirmar(false)
                })
            })
            .catch(err=>{alert(err)
                Swal.fire({
                    html:`Se produjo un error al grabar el movimiento`,
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                }).then(()=>{
                    setConfirmar(false)
                })
            })
           
        }else{ 
            setConfirmar(false)
        }
    }

    return <Box> 
     
        <Box sx={{marginTop:'2rem',display:'flex', justifyContent:'center'}}> 
            <AlertDialog open={confirmar} procesarResultado={procesar} procesarCancelar={procesar} titulo={`¿Confirma el ${descripcion}?`} texto="">
                    {detalles().map((item)=><p style={{padding:'0.25rem'}}>{item}</p>)}
            </AlertDialog>
            <Button onClick={()=>setConfirmar(true)} variant="outlined" color='primary' size='large' endIcon={<SendIcon />}>
               {`Informar el ${descripcion}`}
            </Button>    
        </Box>

     </Box>
}