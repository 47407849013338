import React, {useContext,useEffect,useState,useRef} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'

const meses = [{id:1,nombre:'Enero'},
{id:2,nombre:'Febrero'},
{id:3,nombre:'Marzo'},
{id:4,nombre:'Abril'},
{id:5,nombre:'Mayo'},
{id:6,nombre:'Junio'},
{id:7,nombre:'Julio'},
{id:8,nombre:'Agosto'},
{id:9,nombre:'Septiembre'},
{id:10,nombre:'Octubre'},
{id:11,nombre:'Noviembre'},
{id:12,nombre:'Diciembre'}]

export const SelectMes = ({contexto})=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const {movimiento,handleChangeMes} = useContext(contexto)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black',
            padding:'0.5rem',
            "& .MuiSelect-selectMenu": {
                whiteSpace:'normal',background:'white'
            }
        }
      });

    const classes = useStyle();

    const verificarMesAnio = (item)=>{
    //Esta lógica se escribe para evitar que se seleccione un período mayor al
        // actual en base al año seleccionado y al mes.
        // Se deshabilitan los meses mayores al actual cuando
        // seleccionó el año en curso.

        const fecha = new Date()
        const anio = fecha.getFullYear()
        const mes = fecha.getMonth()

        if (anio==movimiento.anio){
            if(Number(item.id-1)>mes){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
      
        // Nota: Hizo falta una lógica adicional en el context para controlar que sea correcto el perído cuando
        // cambió de año, por ejemplo si estamos en julio 2022 no puede seleccionar agosto 
        // en adelante para el año 2022 , pero si podría seleccionar agosto para el 2021
        // si luego de seleccionar agosto 2021 vuelve al año 2022 se aplica un control
        // en el context y llevo el mes al actual programaticamente.

    }

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <FormControl>
                        {/*<FormLabel id="demo-radio-buttons-group-label">Seleccioná un instrumento</FormLabel>
                        */}
                        <Select
                            labelId="lb-mes"
                            id="sl-mes"
                            value={movimiento.mes}
                            label="Mes"
                            onChange={handleChangeMes}
                            className={classes.select}
                        >
                            <MenuItem value={-1} disabled>Seleccione un mes</MenuItem>
                            {meses.map(item=>{
                                return <MenuItem disabled={verificarMesAnio(item)} value={item.id}>{item.nombre}</MenuItem>
                            })}
                        </Select>
                </FormControl>
            </Box>
}