import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'

export const SelectAnio = ({contexto})=>{
    const [tiposSalida,setTiposSalida] = useState([])
    const {movimiento,handleChangeAnio,crearVectorAnios} = useContext(contexto)

    const anios = crearVectorAnios()

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black',
            padding:'0.5rem',
            "& .MuiSelect-selectMenu": {
                whiteSpace:'normal',background:'white'
            }
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <FormControl>
                        {/*<FormLabel id="demo-radio-buttons-group-label">Seleccioná un instrumento</FormLabel>
                        */}
                        <Select
                            labelId="lb-mes"
                            id="sl-mes"
                            value={movimiento.anio}
                            label="Mes"
                            onChange={handleChangeAnio}
                            className={classes.select}
                        >
                            {anios.map(item=>{
                                return <MenuItem value={item}>{item}</MenuItem>
                            })}
                        </Select>
                </FormControl>
            </Box>
}
