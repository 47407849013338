import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faAngleRight,faAngleLeft } from '@fortawesome/free-solid-svg-icons';
// este componente se usa en conjunto con el hook usePaginacion
// primero se debe importar el hook

// import usePaginacion from '../hooks/usePaginacion';

// Luego se extraen los métodos y variables

// const {iIni, iFin,iMenos,fMenos,iMas,fMas,paginar,mostrar,mostrarMas,mostrarMenos,inicializar} = usePaginacion();

// Luego se pasan los parámetros correspondientes a este componente

export default function Paginacion({configuracion,paginar}){
   
    return <div className="flex justify-center mt-8">
        {configuracion.mostrar && configuracion.mostrarMenos && 
            <span   title={`${configuracion.iMenos+1}-${configuracion.fMenos+1}`} 
                    className="cursor-pointer ml-2 mr-2" 
                    onClick={()=>paginar(configuracion.iMenos,configuracion.fMenos)}>
                        <FontAwesomeIcon icon={faAngleLeft}/>
            </span>}
        <span>{configuracion.iIni+1} - {configuracion.iFin+1}</span>
        {configuracion.mostrar && configuracion.mostrarMas && 
            <span title={`${configuracion.iMas+1}-${configuracion.fMas+1}`} 
                    className="cursor-pointer ml-2" 
                    onClick={()=>paginar(configuracion.iMas,configuracion.fMas)}>
                        <FontAwesomeIcon icon={faAngleRight}/>
            </span>}
</div>
}