import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {egresoContext} from '../contexto/egresoContext'
import SendIcon  from '@mui/icons-material/Send';
import {AlertDialog} from '../../../componentes/MUI/AlertDialog'

export const ButtonEnviar = ()=>{
    const {egreso,enviarInforme,detalles} = useContext(egresoContext)
    const [confirmar,setConfirmar] = useState(false)

    const procesar = (confirmacion) =>{

        if(confirmacion===true){ // para controlar si cerró haciendo click en SI y no afuera del dialog
            enviarInforme()
            .catch(err=>{alert(err)
                    })
            setConfirmar(false)
        }else{ 
            setConfirmar(false)
        }
    }

    return <Box> 
     
        <Box sx={{marginTop:'2rem',display:'flex', justifyContent:'center'}}> 
            <AlertDialog open={confirmar} procesarResultado={procesar} procesarCancelar={procesar} titulo="¿Confirma el egreso?" texto="">
                    {detalles().map((item)=><p style={{padding:'0.25rem'}}>{item}</p>)}
            </AlertDialog>
            <Button onClick={()=>setConfirmar(true)} variant="outlined" color='primary' size='large' endIcon={<SendIcon />}>
                Informar éste egreso
            </Button>    
        </Box>

     </Box>
}