import React, {useContext,useEffect,useState} from 'react'
import {TextField, Button, Box ,InputAdornment,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import Axios from 'axios'
import {egresoContext} from '../contexto/egresoContext'

export const InputMonto = ()=>{
    const {egreso,handleChangeMonto,formatearMontoBlur,formatearMontoFoco} = useContext(egresoContext)

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center' }}> 
                <Box sx={{marginTop:'1rem'}}>
                    <TextField fullWidth 
                        inputProps={{maxLength: 25}} 
                        id="dp-monto" 
                        value={egreso.monto} 
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        onBlur={formatearMontoBlur}
                        onFocus={formatearMontoFoco}
                        onChange={handleChangeMonto}
                       label="Monto" variant="outlined" />
                </Box>
            </Box>
}