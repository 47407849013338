import React from 'react';
//import logo from '../logouad.png'; // Tell webpack this JS file uses this image
import logo from '../logo.JPG'; // Tell webpack this JS file uses this image

console.log(logo); // /logo.84287d09.png

function Logo({width}) {
  // Import result is the URL of your image
  return <img className="centrado" src={logo} alt="Logo" width={width ? width : '150'}/>;
}

export default Logo;