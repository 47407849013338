import React from 'react'
import {useState, useEffect} from 'react'
import Axios from 'axios'
import Logo from '../componentes/Logo'
import {useAlumno} from '../Context/alumnoContext';
import Foto from '../componentes/Foto'
import Main from '../componentes/Main';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SubirFoto } from '../componentes/SubirFoto';
export default function Home(){
    const {usuario,infoObrero,mostrarMenu,esCelular} = useAlumno();

    useEffect(()=>{
        if(esCelular()){
            mostrarMenu()
        }
    },[])

    if (!usuario){
        return null
    }
    return (
            <div className="flex f-col w-100pc justify-center items-center  text-center">
                <Logo/>
                {/*<SubirFoto/>*/}
                {infoObrero.nombre && <p className="mt-4 text-larger">{infoObrero.sexo=='M' ? `Bienvenido ${infoObrero.nombre}` : infoObrero.sexo=='F' ? `Bienvenida ${infoObrero.nombre}` : infoObrero.sexo=='G' ? `Bienvenidos ${infoObrero.nombre}` : `Bienvenido ${infoObrero.nombre}`}</p>}
                {/*usuario.dependiente && <p className="mt-4 text-small color-gray">{obtenerAsociacion(infoObrero,usuario)}</p>*/}
                <FontAwesomeIcon onClick={mostrarMenu} title="Abrir el menú de opciones" className="cursor-pointer color-tomato text-xlarge mt-4 mnhome" icon={faBars}/>
            </div>
    )
}

function obtenerAsociacion(infoObrero,usuario){

    if (usuario.id_iglesia_dependiente){
        if (usuario.id_iglesia_dependiente > 0){ // depende de una iglesia si el id es positivo
            if (infoObrero.sexo=='M'){
                 return `Asociado a la iglesia ${usuario.dependiente}`
            }else{
                return `Asociada a la iglesia ${usuario.dependiente}`
            }
        }else{  // depende de un ministerio si el id es negativo
            if (infoObrero.sexo=='M'){
                return `Asociado al ministerio de ${usuario.dependiente}`
            }else{
                return `Asociada al ministerio de ${usuario.dependiente}`
            }
        }
    }else{
        return ''
    }

}